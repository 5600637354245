<script setup>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import useAuthStore from "../../store/authStore.js";
import {onMounted, ref} from "vue";

const store = useAuthStore();
const userCanShop = ref(false);
const user = ref({
  clientCode: "",
  nome: "",
  cnpj_cpf: "",
});
onMounted(() => {
  let userInfo = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : false;

  userCanShop.value = userInfo ? userInfo.storeAccess : false;

  if (userInfo) {
    let client = userInfo?.current_client
    user.value = {
      clientCode: client?.clientCode,
      nome: client?.name,
      cnpj_cpf: client?.cnpj_cpf,
    };
    console.log('user', user)

  }
});

const formatDoc = (doc) => {
  const cleaned = ("" + doc).replace(/\D/g, "");
  let format = cleaned;

  if (cleaned.length === 11) {
    format = cleaned.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (cleaned.length === 14) {
    format = cleaned.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
    );
  }

  return format;
};
</script>
<template>
  <div
      id="sidenav-collapse-main"
      class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
            collapse-ref="dashboardsExamples"
            nav-text="Usuário "
            class="ms-0"
        >
          <template #icon>
            <i class="ni ni-single-02 text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <div
                class="text-sm d-flex flex-column overflow-hidden"
                style="margin: 0px 10px 10px 25px"
            >
              <span class="mb-1"
                ><b>Cód. Brasal: </b> {{ user.clientCode }}</span
              >
              <span class="mb-1"><b>Nome: </b> {{ user.nome }}</span>
              <span class="mb-1"
              ><b>Doc: </b> {{ formatDoc(user.cnpj_cpf) }}</span
              >
            </div>
          </template>
        </sidenav-collapse>
        <div class="flex flex-row">
          <sidenav-item
              :to="{ name: 'Empresas' }"
              mini-icon="ni-shop"
              text="Empresas"
          />
        </div>
        <div class="flex flex-row">
          <sidenav-item
              :to="{ name: 'Campanhas' }"
              mini-icon="ni-calendar-grid-58"
              text="Campanhas"
          />
        </div>
        <sidenav-collapse
            v-if="userCanShop"
            collapse-ref="dashboardsExamples"
            nav-text="Pedidos"
            class="ms-0"
        >
          <template #icon>
            <i class="ni ni-basket text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <div
                class="text-sm d-flex flex-column"
                style="margin: 0px 10px 10px 10px"
            >
              <div class="flex flex-row">
                <sidenav-item
                    :to="{ name: 'Lista de Produtos' }"
                    mini-icon=""
                    text="Novo Pedido"
                />
              </div>
              <div class="flex flex-row">
                <sidenav-item
                    :to="{ name: 'Novo Pedido' }"
                    mini-icon=""
                    text="Meus Pedidos"
                />
              </div>
            </div>
          </template>
        </sidenav-collapse>
        <div v-else class="flex flex-row">
          <sidenav-item
              :to="{ name: 'Novo Pedido' }"
              mini-icon="ni-shop"
              text="Meus Pedidos"
          />
        </div>
        <div class="flex flex-row">
          <sidenav-item
              :to="{ name: 'Configuracao' }"
              mini-icon="ni-settings-gear-65"
              text="Configuração"
          />
        </div>
        <div class="flex flex-row">
          <sidenav-item
              :to="{ name: 'Duvidas' }"
              mini-icon="ni-air-baloon"
              text="Dúvidas Frequentes"
          />
        </div>
        <div class="flex flex-row">
          <sidenav-item
              :to="{ name: 'Debitos' }"
              mini-icon="ni-credit-card"
              text="Meus Débitos"
          />
        </div>
        <sidenav-collapse
            collapse-ref="dashboardsExamples"
            nav-text="Equipamentos"
            class="ms-0"
        >
          <template #icon>
            <i class="ni ni-mobile-button text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <div
                class="text-sm d-flex flex-column"
                style="margin: 0px 10px 10px 10px"
            >
              <div class="flex flex-row">
                <sidenav-item
                    :to="{ name: 'Equipamentos' }"
                    mini-icon="ni-mobile-button"
                    text="Meus Equipamentos"
                />
              </div>
              <div class="flex flex-row">


                <sidenav-item
                    :to="{ name: 'Solicitar Equipamento' }"
                    mini-icon="ni-fat-add"

                    text="Nova solicitação"
                />
              </div>
            </div>
          </template>
        </sidenav-collapse>
        <div class="flex flex-row">
          <li class="nav-item">
            <router-link
                class="nav-link"
                to="#"
                @click="store.dispatch('logout')"
            >
              <i class="fas fa-power-off"></i>
              <a>Sair</a>
            </router-link>
          </li>
        </div>
      </li>
    </ul>
  </div>
</template>
