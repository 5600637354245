// configStore.js
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

export default {
    state: {
        hideConfigButton: true,
        isPinned: true,
        showConfig: false,
        isRTL: false,
        color: "",
        sidebarType: "bg-white",
        darkMode: false,
        isNavFixed: false,
        isAbsolute: false,
        showNavs: true,
        showSidenav: true,
        wasPinned: false,
        showNavbar: true,
        showFooter: true,
        showMain: true,
        layout: "default",
        bootstrap,
    },
    mutations: {
        toggleConfigurator(state) {
            state.showConfig = !state.showConfig;
        },
        closeSidebar(state) {
            const sidenav_show = document.querySelector(".g-sidenav-show");
            if(sidenav_show.classList.contains("g-sidenav-pinned")) {
                sidenav_show.classList.remove("g-sidenav-pinned");
                sidenav_show.classList.add("g-sidenav-hidden");
                state.isPinned = false;
            }
        },

        navbarMinimize(state) {
            const sidenav_show = document.querySelector(".g-sidenav-show");
            if (sidenav_show.classList.contains("g-sidenav-hidden")) {
                sidenav_show.classList.remove("g-sidenav-hidden");
                sidenav_show.classList.add("g-sidenav-pinned");
                state.isPinned = true;
            } else if (sidenav_show.classList.contains("g-sidenav-pinned")) {
                sidenav_show.classList.add("g-sidenav-hidden");
                sidenav_show.classList.remove("g-sidenav-pinned");
                state.isPinned = false;
            } else if (window.innerWidth < 1200) {
                if (state.isPinned) {
                    state.wasPinned = true;
                }
                sidenav_show.classList.add("g-sidenav-hidden");
                state.isPinned = false;
            } else {
                if (state.wasPinned) {
                    sidenav_show.classList.remove("g-sidenav-hidden");
                    sidenav_show.classList.add("g-sidenav-pinned");
                    state.isPinned = true;
                } else {
                    sidenav_show.classList.add("g-sidenav-hidden");
                }
                state.wasPinned = false;
            }
        },
        setSidebarType(state, payload) {
            state.sidebarType = payload;
        },
        navbarFixed(state) {
            if (state.isNavFixed === false) {
                state.isNavFixed = true;
            } else {
                state.isNavFixed = false;
            }
        },
        toggleDefaultLayout(state) {
            state.showNavbar = !state.showNavbar;
            state.showSidenav = !state.showSidenav;
            state.showFooter = !state.showFooter;
        },
        hideFooter(state) {
            state.showFooter = !state.showFooter;
        }
    },
    actions: {
        toggleSidebarColor({commit}, payload) {
            commit("setSidebarType", payload);
        },
    },
};

