
// icon: 'success', 'error', 'warning', 'info', 'question'
import {toast} from "vue3-toastify";


export default function showToast(icon, message){

  if (icon === 'error') {
  toast.error(message, {
    position: 'top-right',
    duration: 3000,
    isClosable: true
  });
  } else {
    toast.success(message, {
      position: 'top-right',
      duration: 3000,
      isClosable: true
    });

  }

}