import {createRouter, createWebHistory} from "vue-router";

import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";

import SignIn from "../views/conecta-brasal-cliente/auth/SignIn.vue";
import SignUp from "../views/conecta-brasal-cliente/auth/SignUp.vue";
import ResetPass from "../views/conecta-brasal-cliente/auth/ResetPass.vue";
import ValidateToken from "../views/conecta-brasal-cliente/auth/ValidateToken.vue";
import Campaigns from "../views/conecta-brasal-cliente/campanhas/Campaigns.vue";
import Campaign from "../views/conecta-brasal-cliente/campanhas/Campaign.vue";
import CampaignDetails from "../views/conecta-brasal-cliente/campanhas/CampaignDetails.vue";
import Empresas from "../views/conecta-brasal-cliente/empresas/Main.vue";

import MyOrders from "../views/conecta-brasal-cliente/pedidos/meus-pedidos/Main.vue";
import ProductsList from "../views/conecta-brasal-cliente/pedidos/novo-pedido/ProductsList.vue";
import Bonuses from "../views/conecta-brasal-cliente/pedidos/novo-pedido/Bonuses.vue";
import CartPage from "../views/conecta-brasal-cliente/pedidos/carrinho/CartPage.vue";


import Config from "../views/conecta-brasal-cliente/configuracao/Main.vue";
import Faq from "../views/conecta-brasal-cliente/duvidas/Main.vue";
import Debitos from "../views/conecta-brasal-cliente/debitos/Main.vue";
import Equipamentos from "../views/conecta-brasal-cliente/equipamentos/Main.vue";
import Request from "../views/conecta-brasal-cliente/equipamentos/Request.vue";

import {authMiddleWare} from "./middlewares/authMiddleWare";
import ResendEmailToken from "@/views/conecta-brasal-cliente/auth/ResendEmailToken.vue";
import OldCampaign from "@/views/conecta-brasal-cliente/campanhas/OldCampaign.vue";
import OldCampaignDetails from "@/views/conecta-brasal-cliente/campanhas/OldCampaignDetails.vue";

const routes = [
    {
        path: "/",
        name: "/",
        redirect: { name: 'Campanhas' },
        meta: {
            requiresAuth: true
        }

    },
    {
        path: "/auth/signin",
        name: "SignIn",
        component: SignIn
    },
    {
        path: "/auth/signup/:clientCode?/:document?",
        name: "SignUp",
        component: SignUp,
    },
    {
        path: "/auth/reset-password",
        name: "ResetPass",
        component: ResetPass
    },
    {
        path: "/auth/validate-token",
        name: "ValidateToken",
        component: ValidateToken
    },
    {
        path: "/auth/resend-token",
        name: "ResendEmailToken",
        component: ResendEmailToken
    },
    {
        path: "/empresas",
        name: "Empresas",
        component: Empresas,
        meta: {requiresAuth: true, showScrollToTopButton: true}
    },
    {
        path: "/produtos",
        name: "Lista de Produtos",
        component: ProductsList,
        meta: {requiresAuth: true, showScrollToTopButton: true}
    },
    {
        path: "/produtos/bonus",
        name: "Lista de Produtos Bonus",
        component: Bonuses,
        meta: {requiresAuth: true, showScrollToTopButton: true}
    },
    {
        path: "/pedidos",
        name: "Novo Pedido",
        component: MyOrders,
        meta: {requiresAuth: true, showScrollToTopButton: true}
    },
    {
        path: "/pedidos/carrinho",
        name: "Carrinho",
        component: CartPage,
        meta: {requiresAuth: true,
            showScrollToTopButton: false
        }
    },
    {
        path: "/campanhas",
        name: "Campanhas",
        component: Campaigns,
        meta: {requiresAuth: true, showScrollToTopButton: true}
    },
    {
        path: "/campanhas/:id",
        name: "Campanha",
        component: Campaign,
        meta: {requiresAuth: true, showScrollToTopButton: true}
    },
    {
        path: "/campanhas/detalhes/:id",
        name: "Detalhes da Campanha",
        component: CampaignDetails,
        meta: {requiresAuth: true, showScrollToTopButton: true}
    },
    {
        path: "/cofiguracao",
        name: "Configuracao",
        component: Config,
        meta: {requiresAuth: true}
    },
    {
        path: "/duvitas",
        name: "Duvidas",
        component: Faq,
        meta: {requiresAuth: true}
    },
    {
        path: "/debitos",
        name: "Debitos",
        component: Debitos,
        meta: {requiresAuth: true, showScrollToTopButton: true}
    },
    {
        path: "/equipamentos",
        name: "Equipamentos",
        component: Equipamentos,
        meta: {requiresAuth: true, showScrollToTopButton: true}
    },
    {
        path: "/equipamentos/solicitar",
        name: "Solicitar Equipamento",
        component: Request,
        meta: {requiresAuth: true}
    },
    {
        path: "/authentication/error/error404",
        name: "Error Error404",
        component: Error404
    },
    {
        path: "/authentication/error/error500",
        name: "Error Error500",
        component: Error500
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: { name: 'Campanhas' },
        meta: { requiresAuth: true }
    },

    // Campanhas antigas
    {
        path: "/campanhas-prospera/:id/:type",
        name: "Campanha Antiga",
        component: OldCampaign,
        meta: {requiresAuth: true, showScrollToTopButton: true},
        props:true
    },
    {
        path: "/campanhas-prospera/detalhes/:id/:type",
        name: "Detalhes da Campanha Antiga",
        component: OldCampaignDetails,
        meta: {requiresAuth: true}
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active"
});

router.beforeEach(async (to, from, next) => {
    if (to.path.split('/').includes("meus-pedidos")) {
      const clientCanShop = JSON.parse(localStorage.getItem('user')).storeAccess;
      if (!clientCanShop) {
        next({name: 'Campanhas'})
      }
    }
    await authMiddleWare(to, from, next)
});

export default router;
