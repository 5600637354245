<template>
  <main class="main-content main-content-bg mt-0">
    <div class="page-header min-vh-100" :style="{
      backgroundImage: 'url(' + require('@/assets/img/refrigerantes02.jpg') + ')'
    }">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <FeedBack v-if="restrictedClient" :icon="'error'" :title="restrictedClientMessage.title" :subtitle="restrictedClientMessage.message"/>
      <div v-else class="container">
        <div class="row justify-content-center m-auto">
          <div class="col-lg-8 col-md-8 my-2 mw-100 p-0 ">
            <div v-if="!registerstore.state.restricted" class="card w-100 ms-0 border-0 my-2 py-3 py-lg-5  d-flex flex-column flex-lg-row">
              <CardHeader v-if="step === 0" title="Primeiro acesso" subtitle=""/>
              <div v-if="step === 0" class="border-start border-1 d-none d-lg-block mx-2"></div>
              <div class="card-body mx-auto w-lg-50 w-85">
                <div v-if="step === 0" class="d-lg-none text-center text-muted mb-4">
                  <p class="text-sm font-weight-bold mb- text-secondary text-border d-inline z-index-2 bg-white px-3"></p>
                </div>
                <form v-if="step === 0" role="form" class="text-start col">
                  <div class="mb-3 row">
                    <argon-label for="clientCode">Código de Cliente</argon-label>
                    <argon-input
                        id="clientCode"
                        v-model.trim="clientCode"
                        @input="clientCode = clientCode.replace(/\D/g, '')"
                        type="text"
                        placeholder="Digite seu cód. de cliente"
                        aria-label="Código de Cliente"
                        :class="{ 'input-error': firstFormErrors.clientCode !== '' }"
                        :error="firstFormErrors.clientCode !== ''"
                        :success="isValidCode"
                    />
                    <p v-if="firstFormErrors.clientCode !== ''" class="error-message">{{
                        firstFormErrors.clientCode
                      }}</p>
                    <p v-else class="error-message">&nbsp;</p>
                  </div>
                  <div class="mb-3 row">
                    <argon-label for="cpfCnpj">CPF/CNPJ<br>(somente números)</argon-label>
                    <argon-input
                        id="cpfCnpj"
                        v-model.trim="cpfCnpj"
                        @input="cpfCnpj = cpfCnpj.replace(/\D/g, '')"
                        type="text"
                        placeholder="Digite seu documento"
                        aria-label="CPF/CNPJ(somente números)"
                        :class="{ 'input-error': firstFormErrors.cpfCnpj !== '' }"
                        :error="firstFormErrors.cpfCnpj !== ''"
                        :success="isValidCpfCnpj"
                    />
                    <p v-if="firstFormErrors.cpfCnpj !== ''" class="error-message">{{ firstFormErrors.cpfCnpj }}</p>
                    <p v-else class="error-message">&nbsp;</p>
                  </div>
                  <div :class="{'input-error': firstFormErrors.ageCheck !== ''}"
                       class="mb-3 row flex flex-row align-items-center justify-content-center w-100 ps-3">

                    <input
                        id="ageCheck"
                        v-model="ageCheck"
                        type="checkbox"
                        name="ageCheck"
                        aria-label="ageCheck"
                        class="personalizedCheck"
                    >

                    <label for="ageCheck" class="form-check-label w-100 m-0 pe-0 text-nowrap">
                      Declaro ser maior de 18 anos.
                    </label>
                    <p v-if="firstFormErrors.ageCheck !== ''" :class="{'visible':firstFormErrors.ageCheck !== '' }"
                       class="error-message more-below ps-0">{{ firstFormErrors.ageCheck }}</p>
                    <p v-else class="error-message more-below">&nbsp;</p>
                  </div>
                  <div class="text-center">
                    <argon-button
                        :disabled="loadingInitialRegister"
                        color="primary"
                        full-width
                        class="mt-1 mb-2"
                        @click.prevent="handleFormSubmit"
                    >{{ !loadingInitialRegister ? 'Cadastrar' : 'Carregando...' }}
                    </argon-button
                    >
                  </div>
                </form>
                <form v-if="step === 5">
                  <h4>Finalize o cadastro</h4>
                  <p>Os campos assinalados com um * são obrigatórios</p>
                  <div class="mb-1 row">
                    <argon-label for="username">Usuário</argon-label>
                    <argon-input
                      id="username"
                      v-model="username"
                      type="text"
                      placeholder="Usuário"
                      aria-label="Usuário"
                      :class="{ 'input-error': lastFormErrors.username !== '' }"
                      :error="lastFormErrors.username !== ''"
                      :success="username !== ''"
                      disabled
                    />

                    <p v-if="lastFormErrors.username !== ''" class="error-message">{{ lastFormErrors.username }}</p>
                    <p v-else class="error-message">&nbsp;</p>
                  </div>
                  <div class="mb-1 row">
                    <argon-label for="email">E-mail</argon-label>
                    <argon-input
                        id="email"
                        v-model="email"
                        type="email"
                        placeholder="E-mail"
                        aria-label="E-mail"
                        :class="{ 'input-error': lastFormErrors.email !== '' }"
                        :error="lastFormErrors.email !== ''"
                        :success="isEmailValid"
                    />
                    <p v-if="lastFormErrors.email !== ''" class="error-message">{{ lastFormErrors.email }}</p>
                    <p v-else class="error-message">&nbsp;</p>
                  </div>
                  <div class="mb-1 row">
                    <argon-label for="confirmEmail">Confirme o E-mail</argon-label>
                    <argon-input
                        id="confirmEmail"
                        v-model="confirmEmail"
                        type="email"
                        placeholder="Confirme o E-mail"
                        aria-label="Confirme o E-mail"
                        :class="{ 'input-error': lastFormErrors.confirmEmail !== '' }"
                        :error="lastFormErrors.confirmEmail !== ''"
                        :success="isEmailValid && isConfirmEmailValid"
                    />
                    <p v-if="lastFormErrors.confirmEmail !== ''" class="error-message">{{
                        lastFormErrors.confirmEmail
                      }}</p>
                    <p v-else class="error-message">&nbsp;</p>
                  </div>
                  <div class="mb-1 row">
    <argon-label for="phone">Telefone</argon-label>
    <argon-input
      id="phone"
      v-model="phone"
      placeholder="Digite seu telefone"
      aria-label="Telefone"
      type="tel"
      :class="{ 'input-error': lastFormErrors.phone !== '' }"
      :error="lastFormErrors.phone !== ''"
      :success="isValidPhone"
    />
    <p v-if="lastFormErrors.phone !== ''" class="error-message">
      {{ lastFormErrors.phone }}
    </p>
    <p v-else class="error-message">&nbsp;</p>
  </div>
                  <div class="mb-1 row">
                    <argon-label for="password">Senha</argon-label>
                    <custom-password-input
                      id="password"
                      v-model="password"
                      placeholder="Senha"
                      aria-label="Senha"
                      :error="lastFormErrors.password !== ''"
                      :success="allRequirementsMet"
                      :class="{ 'input-error': lastFormErrors.password !== '' }"
                    />
                    <p v-if="lastFormErrors.password !== ''" class="error-message">{{ lastFormErrors.password }}</p>
                    <p v-else class="error-message">&nbsp;</p>
                    <ul :class="{'d-none': allRequirementsMet}" class="password-requirements list-unstyled mt-2"
                        :style="{'color': '#f5365c'}">
                      <li class="text-muted small">A senha deve conter pelo menos:</li>
                      <li :class="{'text-decoration-line-through red-text' : hasMinimumLength}" class="small">6
                        caracteres
                      </li>
                      <li :class="{'text-decoration-line-through red-text' : hasNumber}" class="small">Um número</li>
                      <li :class="{'text-decoration-line-through red-text' : hasLowercaseLetter}" class=" small">Uma
                        letra minúscula
                      </li>
                      <li :class="{'text-decoration-line-through red-text' : hasUppercaseLetter}" class="small">Uma
                        letra maiúscula
                      </li>
                      <li :class="{'text-decoration-line-through red-text' : hasSpecialCharacter}" class=" small">Um
                        caractere especial
                      </li>
                    </ul>
                  </div>
                  <div class="mb-1 row">
                    <argon-label for="confirmPassword">Confirme a Senha</argon-label>
                    <custom-password-input
                      id="confirmPassword"
                      v-model="confirmPassword"
                      placeholder="Confirme a Senha"
                      aria-label="Confirme a Senha"
                      :class="{ 'input-error': lastFormErrors.confirmPassword !== '' }"
                      :error="lastFormErrors.confirmPassword !== ''"
                      :success="password === confirmPassword && confirmPassword !== ''"
                    />
                    <p v-if="lastFormErrors.confirmPassword !== ''" class="error-message">
                      {{ lastFormErrors.confirmPassword }}</p>
                    <p v-else class="error-message">&nbsp;</p>
                  </div>
                  <div class="input-error mb-1 row flex flex-row align-items-center justify-content-start ms-2">
                    <p v-if="lastFormErrors.policyCheck !== ''" :class="{'visible': lastFormErrors.policyCheck !== ''}"
                       class="error-message my-1 ps-0  ">{{ lastFormErrors.policyCheck }}</p>
                    <p v-else class="error-message">&nbsp;</p>
                    <input
                        id="policyCheck"
                        v-model="policyCheck"
                        type="checkbox"
                        class="personalizedCheck"
                        name="policyCheck"
                        aria-label="policyCheck"
                    />

                    <label class="form-check-label w-75 m-0 text-nowrap" for="policyCheck">
                      Concordo com a política.
                    </label>


                  </div>
                  <div class="mb-1 row flex flex-row align-items- justify-content-start ms-2">
                    <input
                        id="notificationCheck"
                        v-model="notificationCheck"
                        type="checkbox"
                        class="personalizedCheck"
                        name="notificationCheck"
                        aria-label="notificationCheck"
                    />
                    <label class="form-check-label w-75 m-0 text-nowrap" for="notificationCheck">
                      Quero receber notificações.
                    </label>
                  </div>
                  <div class="text-center mt-1">
                    <argon-button
                        :disabled="loadingFinalRegister"
                        color="primary"
                        full-width
                        class="mt-1 mb-2"
                        @click.prevent="handleFinalFormSubmit"
                    >{{ !loadingFinalRegister ? 'Finalizar Cadastro' : 'Carregando...' }}
                    </argon-button
                    >
                  </div>
                </form>
                <div v-if="step === 0" class="card-footer bg-transparent text-center p-0 mt-3">
                  <p class="text-start text-muted mb-0">
                    <a class="text-center text-muted mb-0 text-sm text-nowrap">Já tem uma conta?
                      <router-link :to="{ name: 'SignIn' }" class="segment-primary-color  ">Faça
                        login
                      </router-link>
                    </a>
                  </p>
                  <p class="text-start text-muted mb-0">
                    <a class="text-center text-muted mb-0 text-sm text-nowrap">Precisa verificar sua conta?<br>
                      <router-link :to="{ name: 'ResendEmailToken' }" class="segment-primary-color  ">Solicite um novo
                        token
                      </router-link>
                    </a>
                  </p>

                </div>
              </div>
            </div>
            <FeedBack
              v-else icon="error" :title="registerstore.state.restrictionTitle" :subtitle="registerstore.state.restrictionMessage"/>
          </div>
        </div>
      </div>

    </div>

  </main>
  <app-footer/>
</template>

<script setup>
import {computed, onBeforeMount, onBeforeUnmount, ref, watch} from 'vue';
import store from '@/store';
import useRegisterStore from '@/store/registerStore'
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import {cnpj, cpf} from "cpf-cnpj-validator";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import CardHeader from "@/views/conecta-brasal-cliente/auth/components/CardHeader.vue";
import FeedBack from "@/views/conecta-brasal-cliente/auth/components/FeedBack.vue";
import {useRouter} from "vue-router";
import CustomPasswordInput from "./CustomPasswordInput.vue";

const router = useRouter();

const registerstore = useRegisterStore();
const isValidCpfCnpj = computed(() => {
  const isValidCpf = cpf.isValid(cpfCnpj.value);
  const isValidCnpj = cnpj.isValid(cpfCnpj.value);

  return isValidCpf || isValidCnpj;
});

const isValidCode = computed(() => {
  return /^\d+$/.test(clientCode.value);
});

const hasMinimumLength = computed(() => password.value.length >= 6);
const hasNumber = computed(() => /\d/.test(password.value));
const hasLowercaseLetter = computed(() => /[a-z]/.test(password.value));
const hasUppercaseLetter = computed(() => /[A-Z]/.test(password.value));
const hasSpecialCharacter = computed(() => /[@$!%*?&]/.test(password.value));
const allRequirementsMet = computed(() => hasMinimumLength.value && hasNumber.value && hasLowercaseLetter.value && hasUppercaseLetter.value && hasSpecialCharacter.value);
const isEmailValid = computed(() => isValidEmail(email.value));
const isConfirmEmailValid = computed(() => email.value === confirmEmail.value && confirmEmail.value !== '');


const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");

onBeforeMount(() => {
  const isWaitingValidEmail = localStorage.getItem('isWaitingValidEmail');
  if (isWaitingValidEmail) {
    localStorage.removeItem('isWaitingValidEmail');
    router.push({name: 'ValidateToken'});
  }
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
  if (window.innerWidth < 768) {
    document.body.classList.add("g-sidenav-hidden");
  } else {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/enterprise.js?render=6LcUwe0pAAAAAFyQdrEET6P99d4-TBbETyFt1Y5S";
    script.async = true;
    script.defer = true;
    script.classList.add("g-recaptcha");
    document.head.appendChild(script)
    let logo = document.getElementsByClassName("grecaptcha-badge")[0];
    if (logo) {
      logo.style.display = "block";
    }
  }

  if (router.currentRoute.value?.params.clientCode && router.currentRoute.value?.params.document) {
    clientCode.value = router.currentRoute.value.params.clientCode;
    cpfCnpj.value = router.currentRoute.value.params.document;
  }
});

onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
  let script = document.getElementsByClassName("g-recaptcha")[0];
  if (script) {
    document.head.removeChild(script);
  }
  let logo = document.getElementsByClassName("grecaptcha-badge")[0];
  if (logo) {
    if (window.innerWidth < 768) {
      logo.style.display = "none";
    } else {
      logo.style.display = "block";
    }
  }
});

// Estados primeiro formulário
const clientCode = ref('');
const cpfCnpj = ref('');
const ageCheck = ref(false);
const step = ref(0)
const loadingInitialRegister = ref(false)
const firstFormErrors = ref({
  clientCode: '',
  cpfCnpj: '',
  ageCheck: ''
});

const restrictedClient = ref(false);
const restrictedClientMessage = ref({
  title: '',
  message: ''
});

// Estados último formulário
const username = ref(cpfCnpj);
const email = ref('');
const confirmEmail = ref('');
const password = ref('');
const confirmPassword = ref('');
const policyCheck = ref(false);
const notificationCheck = ref(false);
const loadingFinalRegister = ref(false);
const phone = ref('');
const isValidPhone = computed(() => {
  return /^\+?\d{8,15}$/.test(phone.value);
});

watch(() => registerstore.state.loadingFinish, (newValue) => {
  loadingFinalRegister.value = newValue;
});
const lastFormErrors = ref({
  username: '',
  email: '',
  confirmEmail: '',
  password: '',
  confirmPassword: '',
  phone: '',
  policyCheck: '',
  notificationCheck: ''
});
const validateFields = () => {
  if (!clientCode.value) {
    firstFormErrors.value.clientCode = 'Por favor, preencha o código do cliente';
  } else if (!/^\d+$/.test(clientCode.value)) {
    firstFormErrors.value.clientCode = 'O código do cliente deve ser apenas numérico';
  }

  if (!cpfCnpj.value) {
    firstFormErrors.value.cpfCnpj = 'Por favor, preencha o CPF/CNPJ';
  } else if (!isValidCpfCnpj.value) {
    firstFormErrors.value.cpfCnpj = 'Por favor, insira um CPF/CNPJ válido';
  }

  if (!ageCheck.value) {
    firstFormErrors.value.ageCheck = 'Por favor, confirme que você tem mais de 18 anos';
  }
  if (Object.values(firstFormErrors.value).some(error => error !== '')) {
    return false;
  }


  // Se não houver erros, retornar true
  return true;
};

// Função para lidar com o envio do formulário
const handleFormSubmit = async () => {

  firstFormErrors.value = {
    clientCode: '',
    cpfCnpj: '',
    ageCheck: ''
  };
  if (!validateFields()) {
    return;
  }
  /* loadingInitialRegister.value = true; */

  await registerstore.dispatch('initialRegister', {
    clientCode: clientCode.value.trim().replace(/\D/g, ''),
    cpfCnpj: cpfCnpj.value.trim().replace(/\D/g, ''),
    ageCheck: ageCheck.value,
  });

  restrictedClient.value = registerstore.state.restricted;
  if (restrictedClient.value) {
    restrictedClientMessage.value.message = registerstore.state.restrictionMessage;
    restrictedClientMessage.value.title = registerstore.state.restrictionTitle;
    return
  }

  if (registerstore.state.clientFound) {
    step.value = 5
  }

  loadingInitialRegister.value = false;
};

const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const isValidPassword = (password) => {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
  return re.test(password);
};

const validateFieldsFinalForm = () => {

  lastFormErrors.value = {
    username: '',
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    policyCheck: '',
    notificationCheck: '',
    phone: ''
  };

  if (!phone.value) {
    lastFormErrors.value.phone = 'Por favor, preencha o telefone';
  } else if (!isValidPhone.value) {
    lastFormErrors.value.phone = 'Telefone inválido';
  }

  if (!email.value) {
    lastFormErrors.value.email = 'Por favor, preencha o e-mail';
  } else if (!isValidEmail(email.value)) {
    lastFormErrors.value.email = 'Por favor, insira um e-mail válido';
  }

  if (!confirmEmail.value) {
    lastFormErrors.value.confirmEmail = 'Por favor, confirme o e-mail';
  } else if (email.value !== confirmEmail.value) {
    lastFormErrors.value.confirmEmail = 'Os e-mails não correspondem';
  }

  if (!password.value) {
    lastFormErrors.value.password = 'Por favor, preencha a senha';
  } else if (!isValidPassword(password.value)) {
    lastFormErrors.value.password = 'Requisitos de senha não atendidos';
  }

  if (!confirmPassword.value) {
    lastFormErrors.value.confirmPassword = 'Por favor, confirme a senha';
  } else if (password.value !== confirmPassword.value) {
    lastFormErrors.value.confirmPassword = 'As senhas não correspondem';
  }

  if (!policyCheck.value) {
    lastFormErrors.value.policyCheck = 'Por favor, aceite a política';
  }

  if (Object.values(lastFormErrors.value).some(error => error !== '')) {
    return false;
  }

  return true;
};

const handleFinalFormSubmit = async () => {
  if (!validateFieldsFinalForm()) {
    return;
  }
  await registerstore.dispatch('finalizeRegister', {
    phone: phone.value,
    username: username.value,
    email: email.value,
    confirmEmail: confirmEmail.value,
    password: password.value,
    confirmPassword: confirmPassword.value,
    policyCheck: policyCheck.value,
    notificationCheck: notificationCheck.value
  });
  
};

</script>
<style scoped>
/* Oculta o checkbox original */
.personalizedCheck {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Cria um novo checkbox */
.personalizedCheck + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 13px;
  user-select: none;
}

.personalizedCheck + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #f5365c; /* Cor da borda do checkbox */
  border-radius: 4px;
}

/* Estilo quando o checkbox está marcado */
.personalizedCheck:checked + label:before {
  background: #f5365c; /* Cor de fundo quando marcado */
}

.error-message {
  color: #f5365c !important;
  font-size: 0.8em;
  visibility: hidden; /* Adicione esta linha */
  margin-bottom: 0;
  margin-top: -15px;
}

.more-below {
  margin-top: -5px;

}

.input-error + .error-message {
  visibility: visible; /* Adicione esta linha */
}

.input-error + .error-message + .more-below {
  visibility: visible; /* Adicione esta linha */
}

.red-text {
  color: #f5365c;
}
</style>
