<script setup>
import { defineProps, ref, computed, onMounted } from "vue";
import Chart from "chart.js/auto";

const props = defineProps({
  indicator: {
    type: Object,
    required: true,
  },
  isModalGraph: {
    type: Boolean,
  },
});

const showDetailsModal = ref(false);

const chartRef = ref(null);
const chartRef2 = ref(null);

const description = computed(() => props.indicator?.description);

const percentage = computed(() =>
  props.indicator?.evaluation?.clientPercentage
    ? parseFloat(props.indicator?.evaluation?.clientPercentage).toFixed()
    : parseFloat(0).toFixed()
);
const points = computed(() =>
  props.indicator?.evaluation?.clientPoints
    ? parseInt(props.indicator?.evaluation?.clientPoints)
    : 0
);

onMounted(() => {
  let cutout1 = 95;
  let cutout2 = 95;
  if (window.innerWidth < 1500) {
    cutout1 = 50;
    cutout2 = 50;
  }
  if (window.innerWidth < 600) {
    cutout1 = 45;
    cutout2 = 20;
  }

  new Chart(chartRef.value, {
    type: "doughnut",
    data: {
      labels: ["Feito", "Restante"],
      datasets: [
        {
          label: "",
          weight: 9,
          cutout: cutout1,
          tension: 0.9,
          pointRadius: 0,
          borderWidth: 0,
          backgroundColor: ["#4BB543 ", "#AAAAAA"],

          data: [percentage.value, 100 - percentage.value],
          fill: false,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  });

  new Chart(chartRef2.value, {
    type: "doughnut",
    data: {
      labels: ["Feito", "Restante"],
      datasets: [
        {
          label: "",
          weight: 9,
          cutout: cutout2,
          tension: 0.9,
          pointRadius: 0,
          borderWidth: 0,
          backgroundColor: ["#4BB543 ", "#AAAAAA"],

          data: [percentage.value, 100 - percentage.value],
          fill: false,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  });
});
</script>
<template>
  <div class="card mx-auto">
    <div
      v-if="percentage >= 100"
      class="text-center py-2 bg-success text-white"
    >
      Indicador Concluído!
    </div>
    <div v-else class="text-center py-2 bg-gray text-white">
      Em Andamento
    </div>
    <div class="h-100 my-5 py-1">
      <div class="row m-4 pb-5">
        <div class="text-center col-12">
          <div class="chart-container">
            <canvas ref="chartRef" class="chart-canvas" height="213"></canvas>
            <h4 class="font-weight-bold" :class="recievedData ? 'graph-label-h' : 'graph-label'">
              <span>{{ percentage }}%</span>
            </h4>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 pt-2 text-center">
      <h5>{{ indicator.name }}</h5>
    </div>
    <div class="campaign-actions d-flex align-items-center">
      <button
        class="mb-0 btn btn-outline-primary export w-100 m-2 mb-2"
        @click="showDetailsModal = true"
      >
        Detalhes
      </button>
    </div>
  </div>

  <div class="modal" :class="{ 'is-active': showDetailsModal }">
    <div class="modal-background" @click="showDetailsModal = false"></div>
    <div class="modal-content">
      <div class="modal-header">
        <h5 id="exampleModalLabel" class="modal-title">
          Detalhes do Indicador
        </h5>
      </div>
      <div class="modal-body px-1">
        <div>
          <div class="card-body text-center">
            <h4>{{ indicator.name }}</h4>
            <small
              >Período de Apuração:<br />{{
                `${indicator.evaluation.initial_date} até ${indicator.evaluation.final_date}`
              }}</small
            >
          </div>

          <div v-if="percentage >= 100" class="label-colored bg-success mb-2">
            <div>{{ `${percentage}%` }}</div>
          </div>
          <div v-else class="label-colored bg-gray mb-2">
            <div>{{ `${percentage}%` }}</div>
          </div>

          <div class="h-100">
            <div class="row m-4 pb-5">
              <div class="text-center col-12">
                <div class="chart">
                  <canvas
                    ref="chartRef2"
                    class="chart-canvas"
                    height="213"
                  ></canvas>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="description"
            class="mb-1 mx-4 mt-4"
            style="text-align: left"
          >
            <label class="form-label" for="exampleFormControlTextarea1"
              >Descrição:</label
            >
            <textarea
              id="exampleFormControlTextarea1"
              v-model="description"
              readonly
              class="form-control"
              rows="3"
            ></textarea>
          </div>

          <table class="table mx-4">
            <tfoot>
              <tr>
                <th>Pontos</th>
                <th style="text-align: center">
                  {{ `${points}/${indicator?.evaluation?.totalPoints}` }}
                </th>
              </tr>
            </tfoot>
          </table>

          <div class="card-header">
            <h4 class="card-title">Grupos de Produtos</h4>
            <p class="card-text">
              Abaixo veja os grupos e os produtos que você precisa comprar pelo
              menos uma caixa de cada grupo para cumprir essa missão.
            </p>
          </div>
          <div class="card-body px-0">
            <div
              id="accordionExample"
              class="accordion"
              data-toggle-hover="true"
            >
              <div
                v-for="product_group in indicator.product_groups"
                :key="product_group.id"
                class="accordion-item"
              >
                <div
                  :id="'heading_' + product_group.id"
                  class="accordion-header"
                >
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#collapse_' + product_group.id"
                    aria-expanded="false"
                    :aria-controls="'collapse_' + product_group.id"
                  >
                    <div class="row">
                      <div
                        v-if="product_group.status"
                        :id="'sts_geral_' + product_group.id"
                      >
                        <i
                          class="fas fa-check me-2 text-success"
                          aria-hidden="true"
                        ></i
                        ><b class="product-group-title">{{
                          product_group.description
                        }}</b>
                      </div>
                      <div v-else :id="'sts_geral_' + product_group.id">
                        <i
                          class="fa fa-close me-2 text-danger"
                          aria-hidden="true"
                        ></i
                        ><b class="product-group-title">{{
                          product_group.description
                        }}</b>
                      </div>
                    </div>
                  </button>
                </div>
                <div
                  :id="'collapse_' + product_group.id"
                  class="accordion-collapse collapse"
                  :aria-labelledby="'heading_' + product_group.id"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <table>
                      <tr
                        v-for="product in product_group.products"
                        :key="product.id"
                      >
                        <td>
                          <i
                            v-if="product?.orderQuantity > 0"
                            class="fas fa-check me-2 text-success my-3"
                            aria-hidden="true"
                          ></i>
                          <i
                            v-else
                            class="fa fa-close me-2 text-danger my-3"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <th class="product-group-item my-3">
                          {{
                            `${product?.sku?.replace(/^0+/, "")} - ${product?.name}`
                          }}
                        </th>
                      </tr>
                    </table>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          id="close-button"
          type="button"
          class="btn btn-primary"
          @click="showDetailsModal = false"
        >
          Fechar
        </button>
      </div>
    </div>
  </div>
</template>
<style>
.disabled-link {
  pointer-events: none;
  text-decoration: none;
  cursor: not-allowed;
}
.card {
  position: relative;
  margin: 0.25rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  background-color: #ffffff;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  align-items: stretch;
}

.campaign-image {
  width: 100%;
  object-fit: contain;
}

.info {
  margin-top: 1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.card-title {
  font-size: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-header {
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
}

.title {
  font-size: 1rem;
  font-weight: 700;
}

.favorite-icon {
  cursor: pointer;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
}

.bg-gray {
  background-color: #cccccc;
}

.modal {
  display: none;
}

.modal.is-active {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 50%;
  max-height: 80%;
  overflow: auto;
}

@media screen and (max-width: 1150px) {
  .modal-title {
    width: 100%;
    text-align: center;
  }
  
  .modal-content {
    max-width: 100% !important;
    width: 80% !important;
  }

  .midia-box iframe {
    min-height: 50px;
  }

  .midia-box {
    width: 95% !important;
  }
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.label-colored {
  width: 80%;
  margin: auto;
  text-align: center;
  border-radius: 10px;
}

.midia-box {
  width: 70%;
  margin: auto;
  margin-bottom: 50px;
}

.midia-box iframe {
  width: 100%;
  margin: auto;
  min-height: 280px;
}

.midia-box video {
  width: 100%;
  margin: auto;
  min-height: 280px;
}


.chart-container {
  position: relative;
}

.graph-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
</style>
