<!-- components/PasswordInput.vue -->
<template>
  <div class="password-input">
    <argon-input
      class="custom-input"
      :type="showPassword ? 'text' : 'password'"
      v-bind="$attrs"
      v-model="internalValue"
    />
    <i
      :class="showPassword ? 'far fa-eye-slash' : 'far fa-eye'"
      class="password-toggle-icon"
      @click="togglePasswordVisibility"
    ></i>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import ArgonInput from '@/components/ArgonInput.vue';

const showPassword = ref(false);
const internalValue = ref('');
const emit = defineEmits(['update:modelValue']);

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};

watch(internalValue, (newValue) => {
  emit('update:modelValue', newValue);
});
</script>

<style scoped>
.password-input {
  position: relative;
  display: flex;
  align-items: center;
}
.custom-input {
  flex: 1;
}

.password-toggle-icon {
  position: absolute;
  right: 20px;
  cursor: pointer;
  margin-bottom: 16px;
}
</style>
