<template>
  <footer class="py-3 mt-2 h-3 footer">
    <div class="container  d-flex flex-lg-row flex-column align-items-lg-end align-items-center  justify-content-center ">
      <div class="row icones d-flex align-items-center  justify-content-center">
        <div class="col-lg-8 text-center d-flex flex-row justify-content-center align-items-center">
          <a
              href="https:\\www.brasal.com.br/refrigerantes#dell-valle"
              target="_blank"
              class="text-black me-xl-4 me-4"
          >
            <span class="text-lg fab fa-dribbble"></span>
          </a>
          <a
              href="https://twitter.com/Brasalrefri"
              target="_blank"
              class="text-black me-xl-4 me-4"
          >
            <span class="text-lg fab fa-twitter"></span>
          </a>
          <a
              href="https://www.instagram.com/brasalrefrigerantes/"
              target="_blank"
              class="text-black me-xl-4 me-4"
          >
            <span class="text-lg fab fa-instagram"></span>
          </a>
        </div>
      </div>
      <div class="row d-flex align-items-center justify-content-center mt-1">
        <div class=" text-center d-flex align-items-center justify-content-center ">
          <p class="mb-0 text-black text-nowrap ">
            Copyright © {{ new Date().getFullYear() }} Brasal Refrigerantes.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup>
</script>

<style scoped>

@media (min-width: 1048px) {
  .icones{
    margin-left: -60px;
  }
}
</style>