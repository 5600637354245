<template>
  <div class="card px-4 acceptCookies w-100 w-lg-75">
    <div :class="isOffcanvasOpen ? 'offcanvas-show' : 'offcanvas'">
      <div class="offcanvas-header">
        <h5 id="offcanvasBottomLabel" class="offcanvas-title"></h5>
        <button
          type="button"
          class="btn-close text-reset"
          aria-label="Close"
          @click="closeOffcanvas"
        ></button>
      </div>
      <div class="offcanvas-body">
        <p>
          Este site usa cookies e dados pessoais de acordo com os nossos Termos
          de Uso e Política de Privacidade e, ao continuar navegando neste site,
          você declara estar ciente dessas condições..
        </p>
        <br />
        <button
          type="button"
          class="btn btn-success me-1 waves-effect waves-float waves-light"
          @click="acceptCookies"
        >
          Aceitar
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary waves-effect"
          @click="closeOffcanvas"
        >
          Sair
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";

import { useRoute } from "vue-router";
import useAuthStorage from "../../store/authStore.js";

const route = useRoute();
const store = useAuthStorage();

const isOffcanvasOpen = ref(JSON.parse(localStorage.getItem("isOffcanvasOpen")));

function closeOffcanvas() {
  store.dispatch("logout");
}

function acceptCookies() {
  isOffcanvasOpen.value = false;
  localStorage.setItem("isOffcanvasOpen", false);
}

watch(route, () => {
    isOffcanvasOpen.value = JSON.parse(localStorage.getItem("isOffcanvasOpen"))
});

</script>
<style>
.acceptCookies {
  z-index: 9999 !important;
  position: fixed !important;
  bottom: 0;
  width: 80vw !important;
  left: 50%;
  transform: translateX(-50%);
}
</style>
