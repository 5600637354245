<!--
=========================================================
* Vue Argon Dashboard 2 PRO - v4.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<script setup>
import {computed, onMounted, onUnmounted, ref} from "vue";
import {useStore} from "vuex";

import Sidenav from "./examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue"
import AcceptCookies from "@/examples/AcceptCookies/Main.vue";
import {useRoute} from "vue-router";

const store = useStore();
const route = useRoute();
const layout = computed(() => store.state.layout);
const showSidenav = computed(() => store.state.config.showSidenav);
const showNavbar = computed(() => store.state.config.showNavbar);
const showFooter = computed(() => store.state.config.showFooter);
const showConfig = computed(() => store.state.config.showConfig);
const hideConfigButton = computed(() => store.state.config.hideConfigButton);


const isButtonVisible = ref(false);

// pegar o meta da rota, auqle definido no router para ver se é para exibir o botão apenas se uma chabe for verdadeira
// const meta = useRoute().meta;
// const showButton = meta.showScrollToTopButton;

const checkScroll = () => {
  const showButton = route.meta.showScrollToTopButton;
  if (!showButton) {
    return;
  }
  isButtonVisible.value = window.pageYOffset > 0;
};

onMounted(() => {
  window.addEventListener('scroll', checkScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', checkScroll);
});

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

</script>
<template>
  <div class="app-container">
    <div
        v-show="layout === 'landing'"
        class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
    ></div>
    <sidenav v-if="showSidenav"/>
    <main class="main-content position-relative max-height-vh-100 h-100">
      <button
          v-show="isButtonVisible"
          class="scroll-to-top position-fixed bottom-10 ms-auto border-radius-xl bg-segment-secondary-color text-white border-0"
          @click="scrollToTop"
      >
        <i class="fa fa-arrow-up" aria-hidden="true"></i>
      </button>
      <navbar v-if="showNavbar"/>
      <router-view class="view"/>
      <configurator
          :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
      />
      <app-footer v-show="showFooter" class="footer"/>
    </main>
    <accept-cookies/>
  </div>
</template>

<style scoped>
.view{
  min-height: calc(100vh - 150px);
}
.footer {
  margin-top: auto;
}
.scroll-to-top {
  right: 10px;
  padding: 5px;
  width: 35px;
  height: 35px;
  z-index:99999;
}
.main-content {
  position: relative;
  height: 100vh !important;
}

</style>