<template>
  <div>
    <small>QR Code para o PIX</small>
    <canvas ref="qrcodeCanvas"></canvas>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import QRCode from 'qrcode';

// Defina uma propriedade reativa para o código PIX
const props = defineProps({
  pixCode: {
    type: String,
    required: true,
  },
});

// Crie uma referência ao canvas onde o QR Code será gerado
const qrcodeCanvas = ref(null);

// Função para gerar o QR Code
const generateQRCode = async () => {
  try {
    await QRCode.toCanvas(qrcodeCanvas.value, props.pixCode, {
      width: 256,
      errorCorrectionLevel: 'H',
    });
  } catch (error) {
    console.error('Erro ao gerar QR Code:', error);
  }
};

// Gera o QR Code assim que o componente é montado
onMounted(() => {
  generateQRCode();
});
</script>

<style scoped>
canvas {
  margin: 0  auto;
  display: block;
}
</style>
