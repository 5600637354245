/**
=========================================================
* Vue Argon Dashboard 2 PRO - v4.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";

import "primeicons/primeicons.css";
import "primevue/resources/themes/saga-blue/theme.css"; // Importa o tema
import "primevue/resources/primevue.min.css"; // Importa o CSS base do PrimeVue
import "primeicons/primeicons.css"; // Importa os ícones
import "primeflex/primeflex.css"; // (Opcional) Importa o PrimeFlex

import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";
import PrimeVue from "primevue/config";
import Nora from "@primevue/themes/nora";
import "./registerServiceWorker";
import InputNumber from "primevue/inputnumber";
import Message from "primevue/message";
import Checkbox from "primevue/checkbox";
import OneSignalVuePlugin from "@onesignal/onesignal-vue3";
import * as Sentry from "@sentry/vue";

const appInstance = createApp(App);
const focusDirective = {
  mounted(el) {
    el.focus();
  },
};

// Inicia o Sentry
if (process.env.NODE_ENV === 'production' && !!process.env.SENTRY_AUTH_DSN) {
    Sentry.init({
    app: appInstance,
    dsn: process.env.SENTRY_AUTH_DSN,
    integrations: [],
    });
}

appInstance.directive("focus", focusDirective);
appInstance.use(OneSignalVuePlugin, {
  appId: "3e4ea4d7-1ed6-46da-97b3-87efb7cb824e",
  allowLocalhostAsSecureOrigin: true,
});
appInstance.use(PrimeVue, {
  theme: {
    preset: Nora,
    options: {
      prefix: "p",
      darkModeSelector: ".p-dark",
      cssLayer: false,
    },
  },
});
appInstance.use(store);
appInstance.use(router);

appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);

appInstance.component("InputNumber", InputNumber);
appInstance.component("Message", Message);
appInstance.component("Checkbox", Checkbox);
appInstance.use(ArgonDashboard);
appInstance.mount("#app");
