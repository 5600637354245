<script setup>
import {onMounted, ref} from "vue";
import useEquipamentStore from "../../../store/EquipamentStore";
import Loading from "@/components/Loading.vue";
import router from "@/router";

const store = useEquipamentStore();

const requests = ref([]);

const loadingData = ref(true);
const loadError = ref(false);

const selectedEquipament = ref(null);

const formatEquipamento = (equipamento) => {
  return equipamento.replace(/;/g, ' ');
};
function convertDateFormat(str) {
  const [day, month, year] = str.split("/");
  return new Date(`${year}-${month}-${day}`);
}
onMounted(async () => {
  try {
    const myDebtsPromise = store.dispatch("getData");

    await Promise.all([
      myDebtsPromise.then(() => {
        requests.value = store.state.equipamentRequests;
        requests.value.sort((a, b) => convertDateFormat(b.ERDAT) - convertDateFormat(a.ERDAT));
        loadingData.value = false;
        loadError.value = store.state.error;
      }).catch((error) => {
        console.error("Erro ao buscar solicitações de equipamentos:", error);
        loadingData.value = false;
        loadError.value = store.state.error;

      })
    ]);
  } catch (error) {
    console.error("Erro:", error);
    loadingData.value = false;
  }
});
</script>

<template>
  <div class="container">
    <div class="box">
      <section id="faq-search-filter" style="margin-top: 15px" class="mb-4">
        <div class="card faq-search">
          <div class="card-body text-center">
            <!-- main title -->
            <h2 class="">Equipamentos</h2>
            <p class="card-text mb-2">Informações de Equipamentos</p>
          </div>
        </div>
      </section>

      <div v-if="!loadingData">
        <button v-if="!selectedEquipament" @click="() => router.push('/equipamentos/solicitar')" class="btn btn-primary w-100">Solicite aqui
        </button>
        <button v-else @click="() => selectedEquipament = null" class="btn btn-primary w-100">
  <i class="fas fa-arrow-left me-2"></i>
          Voltar
        </button>
      </div>
      <Loading v-if="loadingData"/>
      <div
          v-else-if="!requests?.length"
          class="text-center bg-light-success p-4 mt-2 rounded"
          role="alert"
      >
        Sem Solicitações de equipamentos.
      </div>

      <div
          v-else-if="loadError"
          class="text-center bg-danger text-white p-4 mt-2 rounded"
          role="alert"
      >
        Erro ao carregar as solicitações de equipamentos.
      </div>
      <div v-else-if="!selectedEquipament" class="content-wrapper container-xxl p-0">
        <div class="content-body">
          <!-- Timeline Starts -->
          <section class="basic-timeline">
            <div class="row">
              <div class="col-12">
               
                  <div v-for="(request, index) in requests"
                       :key="index"
                       class="col-12"
                  >
                  <div class="card mt-2 mb-4 h-full cursor-pointer d-flex align-items-center justify-content-center"
                       @click="selectedEquipament = request"
                  >
                    <div class="card-header d-flex flex-column justify-content-between align-items-center">
                      <h4 class="mb-1 text-primary">
                        {{ request.MAKTX }}
                      </h4>

                      <h4 class="mb-0">Status: <span class="text-secondary">{{ request.STATUS }}</span></h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <!-- Timeline Ends -->

      <!-- BEGIN: Content-->
      <div v-else-if="selectedEquipament" class="content-wrapper container-xxl p-0">
        <div class="content-body">
          <!-- Timeline Starts -->
          <section class="basic-timeline">
            <div class="row">
              <div class="col-12">
                <!--Inicio Pedido -->
                <div
                    class="card mt-2 mb-4"
                >
                  <div
                      class="card-header d-flex justify-content-between align-items-center"
                  >
                    <h4>
                      <b>SOLICITAÇÃO: </b>{{ selectedEquipament.NRSOL.replace(/^0+/, "") }}
                    </h4>
                    <div class="mx-4">
                      <b>TIPO: </b>
                      <span v-show="selectedEquipament.TPSOL == 'INS'">INSTALAÇÃO</span>
                      <span v-show="selectedEquipament.TPSOL == 'REC'">RECOLHIMENTO</span>
                    </div>
                    <div>
                      <b>DATA:</b>&nbsp;
                      <span>{{ selectedEquipament.ERDAT }}</span>
                    </div>
                  </div>

                  <div class="card-body">
                    <ul class="list-unstyled">
                      <li class="d-flex flex-row justify-content-between">
                        <div class="timeline-event">
                          <span
                              class="me-2"
                              :class="[
                              selectedEquipament.STATUS_ABERTA == 'S'
                                ? 'text-success'
                                : '',
                              selectedEquipament.STATUS_ABERTA == 'W'
                                ? 'text-warning'
                                : '',
                              selectedEquipament.STATUS_ABERTA == 'E' ? 'text-danger' : '',
                            ]"
                          >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-shopping-cart"
                            >
                              <circle cx="9" cy="21" r="1"></circle>
                              <circle cx="20" cy="21" r="1"></circle>
                              <path
                                  d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"
                              ></path>
                            </svg>
                          </span>
                          <span v-if="selectedEquipament.STATUS_ABERTA == 'S'">
                            Solicitação Aberta
                          </span>
                          <span v-else-if="selectedEquipament.STATUS_ABERTA == 'W'">
                            Solicitação Pendente
                          </span>
                          <span v-else>Solicitação Cancelada</span>
                        </div>
                        <div>
                          <span
                              style="cursor: pointer"
                              class="bg-gray rounded-pill px-3 py-1"
                              data-bs-toggle="collapse"
                              :data-bs-target="`#collapse${selectedEquipament.NRSOL}`"
                              aria-expanded="true"
                              aria-controls="collapse"
                          >
                            Detalhes
                          </span>
                        </div>
                      </li>

                      <div
                          :id="`collapse${selectedEquipament.NRSOL}`"
                          class="collapse my-4 mx-auto"
                      >
                        <div class="d-lg-none"> <!-- Mostra apenas em dispositivos móveis -->
                          <div class="row">
                            <div class="col-12">
                              <strong style="font-size: 0.9rem;">Documento:</strong> {{ selectedEquipament.NRSOL }}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <strong style="font-size: 0.9rem;">Tipo Equip.:</strong> {{ selectedEquipament.TPEQP }}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <strong style="font-size: 0.9rem;">Cód.Material:</strong>
                              {{ parseInt(selectedEquipament.MATNR) }}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <strong style="font-size: 0.9rem;">Equipamento:</strong>
                              <div class="mobile" style="font-size: 0.9rem;"
                                   v-html="formatEquipamento(selectedEquipament.MAKTX)"></div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <strong style="font-size: 0.9rem;">Status Geral:</strong> {{ selectedEquipament.STATUS }}
                            </div>
                          </div>
                        </div>

                        <div class="table-responsive d-none d-lg-block">
                          <table class="table table-striped">
                            <tbody>
                            <tr>
                              <th scope="row">Documento:</th>
                              <td>{{ selectedEquipament.NRSOL }}</td>
                            </tr>
                            <tr>
                              <th scope="row">Tipo Equip.:</th>
                              <td>{{ selectedEquipament.TPEQP }}</td>
                            </tr>
                            <tr>
                              <th scope="row">Cód.Material:</th>
                              <td style="word-wrap: break-word">
                                {{ parseInt(selectedEquipament.MATNR) }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Equipamento:</th>
                              <td class="mobile" style="word-wrap: break-word"
                                  v-html="selectedEquipament.MAKTX.replace(/;/g, ';</br>')"></td>
                              <td class="web" style="word-wrap: break-word" v-html="selectedEquipament.MAKTX"></td>
                            </tr>
                            <tr>
                              <th scope="row">Status Geral:</th>
                              <td>{{ selectedEquipament.STATUS }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <li class="mt-4">
                        <span
                            class="me-2"
                            :class="[
                            selectedEquipament.STATUS_APROVADO == 'S'
                              ? 'text-success'
                              : '',
                            selectedEquipament.STATUS_APROVADO == 'W'
                              ? 'text-warning'
                              : '',
                            selectedEquipament.STATUS_APROVADO == 'E' ? 'text-danger' : '',
                          ]"
                        >
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-dollar-sign"
                          >
                            <line x1="12" y1="1" x2="12" y2="23"></line>
                            <path
                                d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"
                            ></path>
                          </svg>
                        </span>
                        <span v-if="selectedEquipament.STATUS_APROVADO == 'S'">
                          Aprovado
                        </span>
                        <span v-else-if="selectedEquipament.STATUS_APROVADO == 'W'">
                          Reprovado
                        </span>
                        <span v-else>Aguardando</span>
                      </li>
                      <li class="mt-4">
                        <span
                            class="me-2"
                            :class="[
                            selectedEquipament.STATUS_FATURADO == 'S'
                              ? 'text-success'
                              : '',
                            selectedEquipament.STATUS_FATURADO == 'W'
                              ? 'text-warning'
                              : '',
                            selectedEquipament.STATUS_FATURADO == 'E' ? 'text-danger' : '',
                          ]"
                        >
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-file-text"
                          >
                            <path
                                d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"
                            ></path>
                            <polyline points="14 2 14 8 20 8"></polyline>
                            <line x1="16" y1="13" x2="8" y2="13"></line>
                            <line x1="16" y1="17" x2="8" y2="17"></line>
                            <polyline points="10 9 9 9 8 9"></polyline>
                          </svg>
                        </span>
                        <span>Faturado</span>
                      </li>
                      <li class="mt-4">
                        <span
                            class="me-2"
                            :class="[
                            selectedEquipament.STATUS_COMD_GERADO == 'S'
                              ? 'text-success'
                              : '',
                            selectedEquipament.STATUS_COMD_GERADO == 'W'
                              ? 'text-warning'
                              : '',
                            selectedEquipament.STATUS_COMD_GERADO == 'E'
                              ? 'text-danger'
                              : '',
                          ]"
                        >
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-settings"
                          >
                            <circle cx="12" cy="12" r="3"></circle>
                            <path
                                d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
                            ></path>
                          </svg>
                        </span>
                        <span>Comodato Gerado</span>
                      </li>
                      <li class="mt-4">
                        <span
                            class="me-2"
                            :class="[
                            selectedEquipament.STATUS_ENTREGA == 'S' ? 'text-success' : '',
                            selectedEquipament.STATUS_ENTREGA == 'W' ? 'text-warning' : '',
                            selectedEquipament.STATUS_ENTREGA == 'E' ? 'text-danger' : '',
                          ]"
                        >
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-truck"
                          >
                            <rect x="1" y="3" width="15" height="13"></rect>
                            <polygon
                                points="16 8 20 8 23 11 23 16 16 16 16 8"
                            ></polygon>
                            <circle cx="5.5" cy="18.5" r="2.5"></circle>
                            <circle cx="18.5" cy="18.5" r="2.5"></circle>
                          </svg>
                        </span>
                        <span
                            v-if="
                            selectedEquipament.STATUS_ABERTA == 'S' &&
                            selectedEquipament.STATUS == 'ENTREGUE'
                          "
                        >
                          Entregue
                        </span>
                        <span
                            v-else-if="
                            selectedEquipament.STATUS_ABERTA == 'W' &&
                            selectedEquipament.STATUS == 'ENTREGA_PEND'
                          "
                        >
                          Em preparação
                        </span>
                        <span
                            v-else-if="
                            selectedEquipament.STATUS_ABERTA == 'S' &&
                            selectedEquipament.STATUS == 'SAIU_ENTREGA'
                          "
                        >
                          Em rota
                        </span>
                        <span v-else-if="selectedEquipament.STATUS_ABERTA == 'S'">
                          Entregue
                        </span>
                        <span v-else> Não Entregue </span>
                        <div
                            id="collapseExample3 echo $ped['NRSOL'] "
                            class="collapse"
                        ></div>
                      </li>
                      <li class="mt-4">
                        <span
                            class="me-2"
                            :class="[
                            selectedEquipament.STATUS_FINAL == 'S' ? 'text-success' : '',
                            selectedEquipament.STATUS_FINAL == 'W' ? 'text-warning' : '',
                            selectedEquipament.STATUS_FINAL == 'E' ? 'text-danger' : '',
                          ]"
                        >
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-target"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <circle cx="12" cy="12" r="6"></circle>
                            <circle cx="12" cy="12" r="2"></circle>
                          </svg>
                        </span>
                        <span>Concluído</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- Fim do Pedido -->
              </div>
            </div>
          </section>
          <!-- Timeline Ends -->
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.container {
  flex: 1;
}

.box {
  width: 100%;
}

.bg-gray {
  background-color: #cccccc;
}

.mobile {
  display: none;
}

.web {
  display: block;
}
.card-header {
  background-color: #f8f9fa;
}

.card-header h4 {
  font-size: 1.25rem;
}

.card-header .text-primary {
  color: #007bff;
}

.card-header .text-secondary {
  color: #6c757d;
}

.card-text {
  font-size: 1rem;
  color: #212529;
}

@media screen and (max-width: 550px) {
  .card-header {
    flex-direction: column;
    text-align: center;
  }

  .mobile {
    display: block;
  }

  .web {
    display: none;
  }

  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive > .table {
    margin-bottom: 0;
    max-width: none;
  }
}
</style>
