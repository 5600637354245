<script setup>
import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";
import useAuthStore from "../../../store/authStore.js";

import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import CardHeader from "@/views/conecta-brasal-cliente/auth/components/CardHeader.vue";
import CustomPasswordInput from "./CustomPasswordInput.vue";

const store = useStore();
const authStore = useAuthStore();

// const router = useRouter()
const username = ref("");
const password = ref("");
const loadingSignin = ref(false);
const formErrors = ref({ username: "", password: "" });
const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
  if (window.innerWidth < 768) {
    document.body.classList.add("g-sidenav-hidden");
  } else {
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/enterprise.js?render=6LcUwe0pAAAAAFyQdrEET6P99d4-TBbETyFt1Y5S";
    script.async = true;
    script.defer = true;
    script.classList.add("g-recaptcha");
    document.head.appendChild(script);
    let logo = document.getElementsByClassName("grecaptcha-badge")[0];
    if (logo) {
      logo.style.display = "block";
    }
  }
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
  let script = document.getElementsByClassName("g-recaptcha")[0];
  if (script) {
    document.head.removeChild(script);
  }
  let logo = document.getElementsByClassName("grecaptcha-badge")[0];
  if (logo) {
    logo.style.display = "none";
  }
});
const signin = async () => {
  const usernameValue = username.value.trim();
  const passwordValue = password.value.trim();
  formErrors.value = { username: "", password: "" };

  if (!usernameValue || !passwordValue) {
    formErrors.value.username = !usernameValue
      ? "Preencha o campo de usuário"
      : "";
    formErrors.value.password = !passwordValue
      ? "Preencha o campo de senha"
      : "";
    return;
  }

  if (passwordValue.length < 6) {
    formErrors.value.password = "Senha deve ter no mínimo 6 caracteres";
    return;
  }

  loadingSignin.value = true;

  const user = {
    username: usernameValue,
    password: passwordValue,
  };

  await authStore.dispatch("signin", user);
  loadingSignin.value = false;
};
</script>
<template>
  <main class="main-content main-content-bg mt-0">
    <div
      class="page-header min-vh-100"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/refrigerantes02.jpg') + ')',
      }"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center m-auto">
          <div class="col-lg-8 col-md-8 my-2 mw-100">
            <div
              class="card ms-0 border-0 my-2 py-3 py-lg-5 d-flex flex-column flex-lg-row"
            >
              <CardHeader title="" subtitle="" />
              <div class="border-start border-1 d-none d-lg-block mx-2"></div>

              <div class="card-body w-lg-50">
                <div class="d-lg-none text-center text-muted mb-4">
                  <p
                    class="text-sm font-weight-bold mb- text-secondary text-border d-inline z-index-2 bg-white px-3"
                  ></p>
                </div>
                <form role="form" class="text-start col">
                  <div class="mb-3 row">
                    <argon-label for="username">CPF/CNPJ</argon-label>
                    <argon-input
                      id="username"
                      v-model="username"
                      type="text"
                      placeholder="Digite seu usuário"
                      aria-label="Usuário"
                      :error="formErrors.username !== ''"
                      :class="{ 'input-error': formErrors.username !== '' }"
                      pattern="[0-9]*"
                      @input="username = username.replace(/\D/g, '')"
                    />
                    <p v-if="formErrors.username !== ''" class="error-message">
                      {{ formErrors.username }}
                    </p>
                    <p v-else class="error-message">&nbsp;</p>
                  </div>
                  <div class="mb-3 row">
                    <argon-label for="password " class="text-start col-4"
                      >Senha</argon-label
                    >
                    <custom-password-input
                      id="password"
                      v-model="password"
                      placeholder="Digite sua senha"
                      aria-label="Password"
                      :error="formErrors.password !== ''"
                      :class="{ 'input-error': formErrors.password !== '' }"
                    />
                    <router-link
                      :to="{ name: 'ResetPass' }"
                      class="segment-primary-color text-sm mb-0 col text-end text-nowrap pt-1 move-on-hover cursor-pointer custom-class"
                    >
                      Esqueceu sua senha?</router-link
                    >
                    <p v-if="formErrors.password !== ''" class="error-message">
                      {{ formErrors.password }}
                    </p>
                    <p v-else class="error-message">&nbsp;</p>
                  </div>
                  <div class="text-center">
                    <vue-recaptcha
                      ref="recaptcha"
                      @verify="onVerify"
                    ></vue-recaptcha>
                    <argon-button
                      :disabled="loadingSignin"
                      color="primary"
                      full-width
                      class="mt-1 mb-2"
                      @click.prevent="signin"
                      >{{
                        !loadingSignin ? "Entrar" : "Carregando..."
                      }}</argon-button
                    >
                  </div>
                </form>
                <div class="card-footer bg-transparent text-center p-0">
                  <p class="text-center text-muted mb-0 cursor-pointer">
                    <a class="text-center text-muted mb-0 text-sm text-nowrap"
                      >
                      <router-link
                        :to="{ name: 'SignUp' }"
                        class="segment-primary-color"
                      >
                        Primeiro Acesso? Valide o seu cadastro!</router-link
                      ></a
                    >
                  </p>
                </div>
                <div class="card-footer bg-transparent text-center p-0 mt-3">
                  <p class="text-center mb-0" style="font-size: 0.7em; color: #6c757d;">
                    Não tem cadastro com a Brasal Refrigerantes?
                    <br/>
                    Ligue para <a href="tel:08000615000" class="text-primary">0800 061 5000</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>
<style scoped>
.error-message {
  color: red;
  font-size: 0.8em;
  visibility: hidden; /* Adicione esta linha */
  margin-bottom: 0;
  margin-top: -15px;
}

.input-error + .error-message {
  visibility: visible; /* Adicione esta linha */
}

.custom-class {
  margin-top: -15px;
}
</style>
