import {createStore} from "vuex";
import axios from "@/axios/axios_users";
import {ref} from "vue";

import {toast} from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default () => createStore({
    state: {
        userData: ref([]),
        commercial: ref({}),
        general: ref({}),
        accepted: ref({}),
        loadingCommercial: false,
        loadingGeneral: false,
        loadingAccepted: false,
        loading: false,
    },

    mutations: {
        SetData(state, data) {
            state.userData = ref(data);
        },
    },

    actions: {
        async getData(context) {

            context.state.loading = true;

            try {
                const URL = '/client'
                context.state.loading = true;
                const response = await axios.get(URL, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
                    }
                });

                context.commit('SetData', response.data);

                context.state.loading = false;

            } catch (error) {
                context.state.loading = false;
            }
        },

        async updatePassword(context, params) {

            context.state.loading = true;

            const {password, newPassword, confirmationNewPassword} = params

            try {
                const URL = '/client/update-password'
                context.state.loading = true;
                const response = await axios.put(URL, {
                    password,
                    newPassword,
                    confirmationNewPassword,
                }, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
                    }
                });

                context.state.loading = false;

                toast.success(response.data.details);
            } catch (error) {
                console.log(error);
                if (error?.response?.data?.details) {
                    toast.error(error?.response?.data?.details);
                } else {
                    toast.error(error?.message);
                }
                context.state.loading = false;
            }
        },

        async generalPromise(context) {

            context.state.loadingGeneral = true;


            try {
                const URL = '/client/general'
                context.state.loadingGeneral = true;
                const response = await axios.get(URL);

                context.state.loadingGeneral = false;
                context.state.general = response.data;
            } catch (error) {
                if (error?.response?.data?.details) {
                    toast.error(error?.response?.data?.details);
                } else {
                    toast.error(error?.message);
                }
                context.state.loadingGeneral = false;
            }
        },

        async commercialPromise(context) {

            context.state.loadingCommercial = true;

            try {
                const URL = '/client/commercial'
                context.state.loadingCommercial = true;
                const response = await axios.get(URL);

                context.state.loadingCommercial = false;
                context.state.commercial = response.data;
            } catch (error) {
                if (error?.response?.data?.details) {
                    toast.error(error?.response?.data?.details);
                } else {
                    toast.error(error?.message);
                }
                context.state.loadingCommercial = false;
            }
        },

        async acceptedPromise(context) {

                context.state.loadingAccepted = true;

                try {
                    const URL = '/client/notifications'
                    context.state.loadingAccepted = true;
                    const response = await axios.get(URL);

                    context.state.loadingAccepted = false;
                    context.state.accepted = response.data;
                } catch (error) {
                    if (error?.response?.data?.details) {
                        toast.error(error?.response?.data?.details);
                    } else {
                        toast.error(error?.message);
                    }
                    context.state.loadingAccepted = false;
                }
        },

        async updateAcceptances(context, params) {

            context.state.loading = true;

            const {acceptMail, acceptMessages} = params

            try {
                const URL = '/client/notifications'
                context.state.loading = true;
                const response = await axios.put(URL, {
                    acceptMail,
                    acceptMessages,
                }, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
                    }
                });

                context.state.loading = false;

                toast.success(response.data.details);
            } catch (error) {
                console.log(error);
                if (error?.response?.data?.details) {
                    toast.error(error?.response?.data?.details);
                } else {
                    toast.error(error?.message);
                }
                context.state.loading = false;
            }
        },

        async updatePicture(context, params) {

            context.state.loading = true;

            const {form} = params

            var file = form.get("profile");
            if (file !== 'null') {
                var reader = new FileReader();
                reader.onload = function (event) {
                    var base64String = event.target.result;
                    let user = JSON.parse(localStorage.getItem('user'))
                    user.profile = base64String;
                    localStorage.setItem('user', JSON.stringify(user))
                };
                reader.readAsDataURL(file);
            }
            const payload = file !== 'null' ? form : null;
            try {
                const URL = '/client/update-picture'
                context.state.loading = true;
                await axios.post(URL, payload, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                        "Content-Type": "multipart/form-data"
                    }
                });

                context.state.loading = false;

            } catch (error) {
                console.log(error);
                if (error?.response?.data?.details) {
                    toast.error(error?.response?.data?.details);
                } else {
                    toast.error(error?.message);
                }
                context.state.loading = false;
            }
        },
    },
});