<script setup>
import {computed, onMounted, ref, watch} from "vue";
import useProfileStore from "../../../store/ProfileStore";
import {useRouter} from "vue-router";
import Loading from "@/components/Loading.vue";


const router = useRouter();

const activeTab = ref(0);

const store = useProfileStore();

const loadingData = ref(true);

const password = ref("");
const newPassword = ref("");
const confirmationNewPassword = ref("");

const generalData = computed(() =>
    dataStates.general.value ? dataStates.general.value : {}
);
const comertialData = computed(() =>
    dataStates.commercial.value ? dataStates.commercial.value : {}
);
const accepntanceData = computed(() =>
    dataStates.accepted.value ? dataStates.accepted.value : {}
);

const imageUrl = computed(() =>
    dataStates.profile.value ? dataStates.profile.value : null
);


const isAllLoading = computed(() =>
    loading.general.value && loading.commercial.value && loading.accepted.value
);
async function fetchData(promiseName, dataKey, loadingKey, errorKey) {
  if (Object.keys(dataStates[dataKey].value).length === 0) {
    loading[loadingKey].value = true;
    try {
      await store.dispatch(promiseName);
      dataStates[dataKey].value = store.state[dataKey];
      if (dataKey === 'general') {
        dataStates.profile.value= store.state.general.profile;
      }
    } catch (e) {
      errors[errorKey].value = e;
    } finally {
      loading[loadingKey].value = false;
    }
  }
}

const loading = {
  general: ref(true),
  commercial: ref(true),
  accepted: ref(true),
};

const errors = {
  general: ref(null),
  commercial: ref(null),
  accepted: ref(null),
};

const dataStates = {
  general: ref({}),
  profile: ref({}),
  commercial: ref({}),
  accepted: ref({}),
};

watch(activeTab, (newValue) => {
  if (newValue === 0) {
    fetchData("generalPromise", "general", "general", "general");
  } else if (newValue === 2) {
    fetchData("commercialPromise", "commercial", "commercial", "commercial");
  } else if (newValue === 3) {
    fetchData("acceptedPromise", "accepted", "accepted", "accepted");
  }
});

onMounted(() => {
  fetchData("generalPromise", "general", "general", "general");
});


const changePicture = async (event) => {
  let file;
  if (event?.target?.files) {
    file = event.target.files[0];
    const reader = new FileReader();
    if (dataStates?.profile?.value) {
      reader.onload = () => {
        dataStates.profile.value = reader.result;
      };
      reader.readAsDataURL(file);
    }
  } else {
    dataStates.profile.value = null;
    file = null;
  }

  try {
    loadingData.value = true;
    const form = new FormData();
    form.append("profile", file);

    const uptadePicturePromise = store.dispatch("updatePicture", {form});

    await Promise.all([
      uptadePicturePromise.then(() => {
        loadingData.value = false;
        setTimeout(router.go(0), 1500);
      }),
    ]);
    loadingData.value = false;
  } catch (error) {
    console.error("Erro:", error);
    loadingData.value = false;
  }
};

const changePassword = async () => {
  try {
    loadingData.value = true;
    const uptadePasswordPromise = store.dispatch("updatePassword", {
      password: password.value,
      newPassword: newPassword.value,
      confirmationNewPassword: confirmationNewPassword.value,
    });

    await Promise.all([
      uptadePasswordPromise.then(() => {
        loadingData.value = false;
      }).finally(() => {
        password.value = "";
        newPassword.value = "";
        confirmationNewPassword.value = "";
        activeTab.value = 0;
      }),
    ]);
    loadingData.value = false;
  } catch (error) {
    console.error("Erro:", error);
    loadingData.value = false;
  }
};

const updateAcceptances = async () => {
  try {
    loadingData.value = true;
    const uptadeAcceptancesPromise = store.dispatch("updateAcceptances", {
      acceptMail: accepntanceData?.value?.acceptMail,
      acceptMessages: accepntanceData?.value?.acceptMessages,
    });

    await Promise.all([
      uptadeAcceptancesPromise.then(() => {
        loadingData.value = false;
        activeTab.value = 0;
      }).finally(() => {
        activeTab.value = 0;
      })
    ]);
    loadingData.value = false;
  } catch (error) {
    console.error("Erro:", error);
    loadingData.value = false;
  }
};

const tabs = [
  {
    id: "general",
    label: "Geral",
    iconClass: "ni ni-single-02 font-medium-3 me-1",
  },
  {
    id: "password",
    label: "Alterar Senha",
    iconClass: "ni ni-lock-circle-open font-medium-3 me-1",
  },
  {
    id: "info",
    label: "Equipe Comercial",
    iconClass: "ni ni-air-baloon font-medium-3 me-1",
  },
  {
    id: "notifications",
    label: "Notificação",
    iconClass: "ni ni-bell-55 font-medium-3 me-1",
  },
];
</script>
<template>
  <div class="container">

    <div class="box">
      <section style="margin-top: 25px">
        <div class="card faq-search">
          <div class="card-body text-center">
            <h2 class="">Configuração de Conta</h2>
          </div>
        </div>
      </section>
      <Loading v-if="isAllLoading"/>

      <div v-else class="content-body">
        <!-- account setting page -->
        <section id="page-account-settings">
          <div class="row">
            <!-- left menu section -->
            <div class="col-md-3 mb-2 mb-md-0">
              <ul class="nav nav-pills flex-column nav-left p-2 card">
                <!-- general -->
                <li v-for="(tab, index) in tabs" :key="index" class="nav-item">
                  <a
                      :id="'account-pill-' + tab.id"
                      :class="{
                      'nav-link': true,
                      active: activeTab === index,
                      'bg-primary': activeTab === index,
                      'text-white': activeTab === index,
                    }"
                      data-bs-toggle="pill"
                      :href="'#account-vertical-' + tab.id"
                      :aria-expanded="activeTab === index ? 'true' : 'false'"
                      @click="activeTab = index"
                  >
                    <i :class="tab.iconClass"></i>
                    <span class="fw-bold">{{ tab.label }}</span>
                  </a>
                </li>
              </ul>
            </div>
            <!--/ left menu section -->

            <!-- right content section -->
            <div class="col-md-9">
              <div class="card">
                <div class="card-body">
                  <div class="tab-content">
                    <!-- general tab -->
                    <div
                        id="account-vertical-general"
                        role="tabpanel"
                        class="tab-pane active"
                        aria-labelledby="account-pill-general"
                        aria-expanded="true"
                    >
                      <!-- nofit header -->
                      <div class="col-12">
                        <div class="d-flex align-items-center mb-2">
                          <i data-feather="user" class="font-medium-3"></i>
                          <h4 class="mb-0 ms-75">Geral</h4>
                        </div>
                      </div>
                      <div id="resp_ajax_foto"></div>
                      <!-- header section -->
                      <label class="form-label d-block" for="account-username"
                      >Foto do perfil</label
                      >
                      <div class="d-flex">

                        <a href="#" class="me-25 mt-25">
                          <img
                              id="account-upload-img"
                              :src="
                              imageUrl ? imageUrl : '/img/placeholder-image.png'
                            "
                              class="rounded mx-50 me-2"
                              alt="profile image"
                              height="100"
                              width="100"
                          />
                        </a>
                        <!-- upload and reset button -->
                        <form method="post" enctype="multipart/form-data">
                          <div class="mt-75 ms-1">

                            <label
                                for="account-upload"
                                class=" d-flex flex-row align-items-center justify-content-center gap-1 btn btn-sm btn-danger text-nowrap m-0 mb-1  w-75 w-lg-50"
                            >
                              <i class="fa fa-pencil fa-fw"></i>
                              Atualizar</label
                            >
                            <input
                                id="account-upload"
                                type="file"
                                class="btn btn-sm btn-outline-secondary mb-75 "
                                hidden
                                accept="image/*"
                                :disabled="loading.general.value"
                                @change="changePicture"
                            />


                            <button
                                id="account-upload-reset"
                                type="button"
                                class="btn btn-sm btn-outline-secondary mb-0 d-flex align-items-center justify-content-center w-75 w-lg-50"
                                :disabled="loading.general.value"
                                @click="changePicture"
                            >
                              <i class="fa fa-trash-o fa-fw me-1"></i>
                              Remover
                            </button>
                            <p class="text-sm">Permitido JPG, GIF ou PNG com tamanho máximo de 800kB.</p>
                          </div>
                        </form>
                        <!--/ upload and reset button -->
                      </div>
                      <!--/ header section -->

                      <!-- form -->
                      <form class="validate-form mt-2">
                        <div class="row">
                          <div class="col-12 col-sm-6">
                            <div class="mb-1">
                              <label class="form-label" for="account-username"
                              >Usuário</label
                              >
                              <input
                                  id="account-username"
                                  readonly
                                  type="text"
                                  class="form-control"
                                  name="username"
                                  placeholder="Username"
                                  :value="generalData?.user"
                              />
                            </div>
                          </div>
                          <div class="col-12 col-sm-6">
                            <div class="mb-1">
                              <label class="form-label" for="account-name"
                              >Empresa</label
                              >
                              <input
                                  id="account-name"
                                  readonly
                                  type="text"
                                  class="form-control"
                                  name="name"
                                  placeholder="Name"
                                  :value="generalData?.name"
                              />
                            </div>
                          </div>
                          <div class="col-12 col-sm-6">
                            <div class="mb-1">
                              <label class="form-label" for="account-e-mail"
                              >E-mail</label
                              >
                              <input
                                  id="account-e-mail"
                                  readonly
                                  type="email"
                                  class="form-control"
                                  name="email"
                                  placeholder="Email"
                                  :value="generalData?.email"
                              />
                            </div>
                          </div>
                          <div class="col-12 col-sm-6">
                            <div class="mb-1">
                              <label class="form-label" for="cpf/cnpj"
                              >CPF/CNPJ</label
                              >
                              <input
                                  id="cpf/cnpj"
                                  readonly
                                  type="text"
                                  class="form-control"
                                  name="cpf/cnpj"
                                  placeholder="cpf/cnpj"
                                  :value="generalData?.cnpj_cpf"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                      <!--/ form -->
                    </div>
                    <!--/ general tab -->

                    <!-- change password -->
                    <div
                        id="account-vertical-password"
                        class="tab-pane fade"
                        role="tabpanel"
                        aria-labelledby="account-pill-password"
                        aria-expanded="false"
                    >
                      <!-- nofit header -->
                      <div class="col-12">
                        <div class="d-flex align-items-center mb-2">
                          <i data-feather="lock" class="font-medium-3"></i>
                          <h4 class="mb-0 ms-75">Alterar Senha</h4>
                        </div>
                      </div>
                      <!-- form -->
                      <form class="validate-form">
                        <div id="resp_ajax"></div>
                        <div class="row">
                          <div class="col-12 col-sm-6">
                            <div class="mb-1">
                              <label
                                  class="form-label"
                                  for="account-old-password"
                              >Senha atual</label
                              >
                              <div
                                  style="border-color: red"
                                  class="input-group form-password-toggle input-group-merge"
                              >
                                <input
                                    id="account-old-password"
                                    v-model="password"
                                    type="password"
                                    class="form-control"
                                    name="password"
                                    placeholder="Senha Atual"
                                    :disabled="loading.general.value"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-6">
                            <div class="mb-1">
                              <label
                                  class="form-label"
                                  for="account-new-password"
                              >Senha nova</label
                              >
                              <div
                                  class="input-group form-password-toggle input-group-merge"
                              >
                                <input
                                    id="account-new-password"
                                    v-model="newPassword"
                                    type="password"
                                    name="new-password"
                                    class="form-control"
                                    placeholder="Nova Senha"
                                    :disabled="loading.general.value"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-6">
                            <div class="mb-1">
                              <label
                                  class="form-label"
                                  for="account-retype-new-password"
                              >Repetir a senha nova</label
                              >
                              <div
                                  class="input-group form-password-toggle input-group-merge"
                              >
                                <input
                                    id="account-retype-new-password"
                                    v-model="confirmationNewPassword"
                                    type="password"
                                    class="form-control"
                                    name="confirm-new-password"
                                    placeholder="Nova Senha"
                                    :disabled="loading.general.value"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <button
                                style="width: 100%"
                                type="button"
                                class="btn btn-danger btn-block me-1 mt-1"
                                @click="changePassword"
                            >
                              Salvar
                            </button>
                          </div>
                        </div>
                      </form>
                      <!--/ form -->
                    </div>
                    <!--/ change password -->

                    <!-- information -->
                    <div
                        id="account-vertical-info"
                        class="tab-pane fade"
                        role="tabpanel"
                        aria-labelledby="account-pill-info"
                        aria-expanded="false"
                    >
                      <!-- nofit header -->
                      <div class="col-12">
                        <div class="d-flex align-items-center mb-2">
                          <i data-feather="info" class="font-medium-3"></i>
                          <h4 class="mb-0 ms-75">Dados Comerciais</h4>
                        </div>
                      </div>
                      <Loading v-if="loading.commercial.value" short/>
                      <!-- form -->
                      <form v-else class="validate-form">
                        <div class="row">
                          <div class="col-12 col-sm-6">
                            <div class="mb-1">
                              <label class="form-label" for="Gerente"
                              >Gerente</label
                              >
                              <input
                                  id="Gerente"
                                  readonly
                                  type="text"
                                  class="form-control"
                                  placeholder="Gerente"
                                  name="dob"
                                  :value="comertialData?.manager"
                              />
                            </div>
                          </div>
                          <div class="col-12 col-sm-6">
                            <div class="mb-1">
                              <label class="form-label" for="Coordenador"
                              >Coordenador</label
                              >
                              <input
                                  id="Coordenador"
                                  readonly
                                  type="text"
                                  class="form-control"
                                  placeholder="Coordenador"
                                  name="dob"
                                  :value="comertialData?.coordinator"
                              />
                            </div>
                          </div>
                          <div class="col-12 col-sm-6">
                            <div class="mb-1">
                              <label class="form-label" for="Vendedor1"
                              >Vendedor</label
                              >
                              <input
                                  id="Vendedor1"
                                  readonly
                                  type="text"
                                  class="form-control"
                                  name="website"
                                  placeholder="Vendedor"
                                  :value="comertialData?.figureName"
                              />
                            </div>
                          </div>
                          <div class="col-12 col-sm-6">
                            <div class="mb-1">
                              <label class="form-label" for="Vendedor"
                              >Telefone Vendedor</label
                              >
                              <input
                                  id="Vendedor"
                                  readonly
                                  type="text"
                                  class="form-control"
                                  placeholder="Telefone Vendedor"
                                  :value="
                                  comertialData?.cellphone
                                    ? `${comertialData?.cellphone}`
                                    : null
                                "
                                  name="phone"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                      <!--/ form -->
                    </div>
                    <!--/ information -->

                    <!-- notifications -->
                    <div
                        id="account-vertical-notifications"
                        class="tab-pane fade"
                        role="tabpanel"
                        aria-labelledby="account-pill-notifications"
                        aria-expanded="false"
                    >
                      <!-- nofit header -->
                      <div class="col-12">
                        <div class="d-flex align-items-center mb-2">
                          <i data-feather="bell" class="font-medium-3"></i>
                          <h4 class="mb-0 ms-75">Configurar Notificações</h4>
                        </div>
                      </div>
                      <Loading v-if="loading.accepted.value" short/>
                      <div v-else class="row">
                        <h6 class="section-label mt-2">Aceites</h6>
                        <div class="col-12 mt-1 mb-2">

                          <div class="form-check form-switch">
                            <input
                                id="accountSwitch4"
                                v-model="accepntanceData.acceptMail"
                                type="checkbox"
                                class="form-check-input"
                                :checked="accepntanceData?.acceptMail"
                                :disabled="loading.accepted.value"
                            />
                            <label class="form-check-label" for="accountSwitch4"
                            >Notícias e Anúncios por e-mail</label
                            >
                          </div>
                        </div>
                        <div class="col-12 mb-2">
                          <div class="form-check form-switch">
                            <input
                                id="accountSwitch6"
                                v-model="accepntanceData.acceptMessages"
                                type="checkbox"
                                class="form-check-input"
                                :checked="accepntanceData?.acceptMessages"
                                :disabled="loading.accepted.value"
                            />
                            <label class="form-check-label" for="accountSwitch6"
                            >Mensagens Promocionais</label
                            >
                          </div>
                        </div>
                        <button
                            style="width: 100%"
                            type="button" class="btn btn-danger btn-block me-1 mt-1" @click="updateAcceptances"
                        >
                          Salvar
                        </button>
                      </div>
                    </div>
                    <!--/ notifications -->
                  </div>
                </div>
              </div>
            </div>
            <!--/ right content section -->
          </div>
        </section>
        <!-- / account setting page -->
      </div>
    </div>
  </div>
</template>
<style>
.container {
  flex: 1;
}

.box {
  width: 100%;
}
</style>
