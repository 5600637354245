<script setup>
import {onBeforeMount, ref} from "vue";
import companiesStore from "@/store/companiesStore";
import Empresa from "./empresa/Main.vue";
import Loading from "@/components/Loading.vue"
import {cnpj as cnpjValidator} from "cpf-cnpj-validator";

import {toast} from "vue3-toastify";

const companiesError = ref(null);
const companies = ref([]);
const loading = ref(false);
const loadingGroupRequests = ref(false);
const loadingCreateGroupRequest = ref(false);
const createGroupRequestError = ref(null);
const currentUser = ref(null);
const showRegisterCompanyModal = ref(false);
const requests = ref([]);

const companyCnpj = ref('');
const companiesCnpj = ref([]);
const companyGroup = ref('');
const groupName = ref('');

const errors = ref({});

const handleInput = () => {
  if (companyCnpj.value.replace(/\D/g, '').length >= 14) {
    addCnpj();
  }
};

const formatDate = (date) => {
  return new Date(date).toLocaleDateString();
}
const getStatusText = (status) => {
  const statusMap = {
    created: 'Chamado aberto',
    // pending: 'Em análise',
    concluded: 'Aprovado',
    canceled: 'Rejeitado'
  };
  return statusMap[status] || 'Desconhecido';
}
const getStatusIcon = (status) => {
  const iconMap = {
    created: 'fas fa-hourglass-start text-blue-500',
    // pending: 'fas fa-hourglass-half text-yellow-500',
    concluded: 'fas fa-check-circle text-green-500',
    canceled: 'fas fa-times-circle text-red-500'
  };
  return iconMap[status] || 'fas fa-question-circle text-gray-500';
}

const addCnpj = () => {
  errors.value.companyCnpj = null;

  // Remove caracteres não numéricos e divide a string em blocos de 14 dígitos
  const cleanedInput = companyCnpj.value.replace(/\D/g, '');
  const cnpjs = cleanedInput.match(/.{1,14}/g) || [];
  const invalidDocuments = [];

  cnpjs.forEach(cnpj => {
    if (cnpj.length !== 14) {
      invalidDocuments.push(cnpj);
    } else if (!cnpjValidator.isValid(cnpj)) {
      invalidDocuments.push(cnpj);
      errors.value.companyCnpj = `Documento inválido: ${cnpj}`;
    } else if (companiesCnpj.value.includes(cnpj)) {
      errors.value.companyCnpj = `Documento já adicionado: ${cnpj}`;
    } else {
      companiesCnpj.value.push(cnpj);
    }
  });

  // Atualiza o campo de input com os documentos inválidos ou incompletos, separados por espaços
  companyCnpj.value = invalidDocuments.join(' ');

  if (!errors.value.companyCnpj && invalidDocuments.length === 0) {
    companyCnpj.value = '';
  }
};

const validateForm = () => {
  errors.value = {};


  if (!companiesCnpj.value.length) {
    errors.value.companyCnpj = 'Informe ao menos um documento válido da empresa que deseja incluir';
  }

  if (!companyGroup.value.trim()) {
    errors.value.companyGroup = 'Nome do agrupamento é obrigatório';
  }

  return Object.keys(errors.value).length === 0;
};

const handleSubmit = async () => {
  if (validateForm()) {
    const groupRequest = {
      group_name: companyGroup.value,
      requested_documents: companiesCnpj.value,
      requesting_document: currentUser.value.current_client.cnpj_cpf,
      notes: ''
    };
    try {
      loadingCreateGroupRequest.value = true;
      await companiesStore.dispatch("createCompanyGroup", groupRequest);
      if (!companiesStore.getters.createGroupError) {
        toast.success('Solicitação de inclusão enviada com sucesso');
        showRegisterCompanyModal.value = false;
        await getGroupRequests();
      } else {
        toast.error('Erro ao criar solicitação de inclusão');
      }
    } finally {
      loadingCreateGroupRequest.value = false;
      createGroupRequestError.value = companiesStore.getters.groupRequestsError;
    }

  }
};
async function getCompanies() {
  loading.value = true;

  try {
    await companiesStore.dispatch("fetchCompanies");
    companies.value = companiesStore.getters.companies;

  } finally {
    loading.value = false;
    companiesError.value = companiesStore.getters.error;

  }
}

async function getGroupRequests() {
  try {
    loadingGroupRequests.value = true;
    await companiesStore.dispatch("fetchGroupRequests");
    requests.value = companiesStore.getters.groupRequests;
  } catch (error) {
    toast.error('Erro ao buscar solicitações de inclusão de empresas');

  }
  loadingGroupRequests.value = false;

}

onBeforeMount(async () => {
  currentUser.value = JSON.parse(localStorage.getItem("user"));
  if (currentUser.value.group) {
    groupName.value = currentUser.value?.group?.name;
    companyGroup.value = currentUser.value?.group?.name;
  }
  await getCompanies();
  await getGroupRequests();
});
</script>

<template>
  <main>
    <div class="card text-center py-3 mx-auto col-11">
      <h1 class="p-0 m-0">Empresas</h1>
      <p class="current-company-message mx-auto m-0">Acesse suas empresas </p>
    </div>
    <Loading v-if="loading" class="mb-auto"/>
    <div class="p-3 p-lg-5">

      <div v-if="companies?.length > 0 && !loading" class="grid-container">
        <div v-for="company in companies" :key="company.id">
          <Empresa :company="company" :companyLogged="company.cnpj_cpf === currentUser.current_client.cnpj_cpf"
                   @register-company="handleRegisterCompany"/>
        </div>
      </div>
      <div v-else-if="companiesError" class="text-center bg-danger p-4 my-2   rounded text-white"
           role="alert">
        <p class="text-white mb-0">{{ companiesError }}</p>
      </div>
      <div v-if="requests.length > 0 && !loadingGroupRequests" class="grid-container text-gray-700 mt-4">
        <h3 class="text-2xl font-bold text-primary mb-0">Solicitações de inclusão</h3>
        <div v-for="request in requests" :key="request.id"
             class="card p-4 m-0 shadow-lg rounded-lg border border-gray-300">

          <p class="text-sm text-gray-700 mb-2">
            <strong>Data de Criação:</strong> {{ formatDate(request.created_at) }}
          </p>

          <p class="text-sm text-gray-700 mb-2 flex items-center">
            <strong>Status:</strong>
            <span class="ml-2 flex items-center">
          <i :class="getStatusIcon(request.request_status)" class="mr-2"></i>
          {{ getStatusText(request.request_status) }}
        </span>
          </p>

          <p class="text-sm text-gray-700 mb-4">
            <strong>Nome do Grupo:</strong> {{ request.group_name }}
          </p>

          <p class="text-sm text-gray-700 mb-2"><strong>Documentos Solicitados:</strong></p>
          <ul class="list-disc list-inside text-sm text-gray-700">
            <li v-for="doc in request.requested_documents" :key="doc.id">{{
                cnpjValidator.format(doc.requested_document)
              }}
            </li>
          </ul>
        </div>
      </div>
      <div class="card p-2 m-0 mt-3 text-xl text-center text-normal cursor-pointer border-primary border-2"
           @click="showRegisterCompanyModal = true">
        <p class="inclusion-request">Não identificou alguma empresa? <br> <u class="text-bold"> Solicite a inclusão por
          aqui.</u></p>
      </div>


    </div>


  </main>
  <div class="modal mx-auto" :class="{ 'is-active': showRegisterCompanyModal }">
    <div class="modal-background" @click="() => showRegisterCompanyModal = false"></div>
    <div class="modal-content">
      <div class="modal-header bg-gray-300">
        <h5 id="exampleModalLabel" class="modal-title text-secondary">
          Inclusão no agrupamento:
        </h5>
      </div>
      <div v-if="!loadingCreateGroupRequest && !createGroupRequestError" class="modal-body">
        <form class="form">
          <div class="form-group">
            <label for="companyGroup">Nome pretendido para a sua rede:</label>
            <input type="text" class="form-control" id="companyGroup" v-model="companyGroup"
                   :class="{ 'input-error': errors.companyGroup }" aria-describedby="companyGroupHelp"
                   placeholder="Insira o nome"
                   :disabled="groupName !== ''"
            />

            <div v-if="errors.companyGroup" class="text-danger text-sm">{{ errors.companyGroup }}</div>
          </div>
          <div>

          </div>

          <div class="form-group">
            <label for="companyCnpj">CNPJs</label>
            <input type="text" class="form-control" id="companyCnpj" v-model="companyCnpj"
                   @input="handleInput"
                   :class="{ 'input-error': errors.companyCnpj }"
                   aria-describedby="companyCnpjHelp" placeholder="Insira os CNPJs da sua rede">

            <div v-if="errors.companyCnpj" class="text-danger text-sm">{{ errors.companyCnpj }}</div>
          </div>
          <div
              class="w-100 text-normal bg-gray d-flex flex-row mt-1 justify-content-between align-items-center p-2 border-radius-sm"
              v-for="cnpj in companiesCnpj"
              :key="cnpj"
          >
            <p class="mb-0">{{ cnpjValidator.format(cnpj) }}</p>
            <span>
            <i class="fa fa-pencil cursor-pointer" aria-hidden="true"
               @click="companyCnpj = cnpj; companiesCnpj = companiesCnpj.filter(c => c !== cnpj)"
            ></i>
<i class="fa fa-times-circle ms-3 cursor-pointer" aria-hidden="true"
   @click="companiesCnpj = companiesCnpj.filter(c => c !== cnpj)"
></i>
            </span>
          </div>
        </form>

      </div>
      <div v-else-if="createGroupRequestError" class="modal-body">
        <div class="alert alert-danger" role="alert">
          Erro ao criar solicitação de inclusão. Tente novamente mais tarde.
        </div>
      </div>
      <div v-else class="modal-body" style="height: 200px;">
        <Loading short/>
      </div>
      <div class="modal-footer d-flex flex-row justify-content-between">
        <button type="button" class="btn btn-secondary" @click="() => showRegisterCompanyModal = false">Fechar</button>
        <button type="button" class="btn btn-success" @click="handleSubmit" :disabled="loadingCreateGroupRequest">
          Enviar
        </button>
      </div>
    </div>
  </div>
</template>


<style scoped>


.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;

}

.cursor-pointer {
  cursor: pointer;
}

@media (min-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

}

.current-company-message {
  text-align: left;
  margin-bottom: 1rem;
  padding: 0 1rem;
}

.inclusion-request {
  font-size: medium;
  margin: 0;
}

.modal-content {
  max-width: 90vw;
  padding: 0;
}
</style>