<template>
  <div v-if="item" class="grid-item ">
    <img v-if="image" :src="image" alt="item" class="image"/>
    <img v-else src="@/assets/img/combo-default.jpg" alt="item" class="image"/>
    <p class="name">
      {{description ? description : descricao + " " + cleanedComplement }}
    </p>
    <p class="name mt-5">
    </p>
    <InputNumber
        class="input-number"
        v-model="quantityState"
        inputId="horizontal-buttons"
        mode="decimal"
        buttonLayout="horizontal"
        showButtons
        :min="0"
        :max="estoque || maximum"
        :step="1"
        @input="checkPriceRange"
    >
      <template #incrementbuttonicon>
        <i class="pi pi-plus"></i>
      </template>
      <template #decrementbuttonicon>
        <i v-if="isToDelete" class="pi pi-trash p-1"></i>
        <i v-else class="pi pi-minus"></i>
      </template>
      >
    </InputNumber>

    <p v-if="priceMax > (quantityState * actualPrice)" class="price-max" >
      <del>{{ "R$ " + formatCurrency(priceMax) }}</del>
    </p>

<span class="price" :style="{ color: priceMax > (quantityState * actualPrice) ? '#047857' : '#333' }">

  {{ "R$ " + formatCurrency(quantityState * actualPrice) }} <br>
  </span>
  </div>
  
</template>

<script setup>
import {onBeforeMount, ref, computed, watch} from 'vue';
import {debounce} from 'lodash';

onBeforeMount(
    () => {
      const footer = document.querySelector("footer");
      if (footer) {
        footer.style.display = "none";
      }
    }
)
const props = defineProps(
    {
      item: Object,
    }
);

const emits = defineEmits(['quantity-changed', 'item-removed']);
const isToDelete = computed(() => {
  return quantityState.value === 1;
});
const {item} = props;
const {descricao, description, complemento, total, usePrice, image, maximum, price, preco} = item;
const cleanedComplement = complemento?.replace(/ - R\$$/, '')

const debouncedInputHandler = debounce(() => {
  const productsOnCart = JSON.parse(localStorage.getItem('productsOnCart'));
  if (!productsOnCart) {
    return;
  }
  const cartItem = productsOnCart.find(product => product.ean === item.ean);

  if (!cartItem) {
    return;
  }
  const discount = cartItem.preco - actualPrice.value;

  if (cartItem) {
    cartItem.discount = discount;
    cartItem.total = quantityState.value;
    cartItem.usePrice = actualPrice.value;
  }


  localStorage.setItem('productsOnCart', JSON.stringify(productsOnCart));

  emits('quantity-changed', quantityState.value);
}, 300);

function checkPriceRange() {
  if (quantityState.value && quantityState.value != 0) {
    let usePrice = item?.preco || item?.price;
    if (
        quantityState.value >= (item.faixaVolume5 || item.price_ranges?.faixaVolume5) &&
        (item.kdkg5 > 0 || item.price_ranges?.kdkg5 > 0)
    ) {
      usePrice = (item.kdkg5 || item.price_ranges?.kdkg5);
    } else if (
        quantityState.value >= (item.faixaVolume4 || item.price_ranges?.faixaVolume4) &&
        (item.kdkg4 > 0 || item.price_ranges?.kdkg4 > 0)
    ) {
      usePrice = (item.kdkg4 || item.price_ranges?.kdkg4);
    } else if (
        quantityState.value >= (item.faixaVolume3 || item.price_ranges?.faixaVolume3) &&
        (item.kdkg3 > 0 || item.price_ranges?.kdkg3 > 0)
    ) {
      usePrice = (item.kdkg3 || item.price_ranges?.kdkg3);
    } else if (
        quantityState.value >= (item.faixaVolume2 || item.price_ranges?.faixaVolume2) &&
        (item.kdkg2 > 0 || item.price_ranges?.kdkg2 > 0)
    ) {
      usePrice = (item.kdkg2 || item.price_ranges?.kdkg2);
    } else if (quantityState.value === 0) {
      usePrice = 0;
    }
    actualPrice.value = usePrice;
  } else {
    const productsOnCart = JSON.parse(localStorage.getItem('productsOnCart'));
    const newProductsOnCart = productsOnCart.filter(product => product.ean !== item.ean);
    localStorage.setItem('productsOnCart', JSON.stringify(newProductsOnCart));
    emits('item-removed');
    return
  }
  debouncedInputHandler();
}

const actualPrice = ref(usePrice)
const priceMax = computed(() => {
  if (quantityState.value === 0) {
    return 0;
  }
  return (preco ||price) * quantityState.value;
});

const quantityState = ref(total);

watch(
    () => quantityState.value,
    () => {
      checkPriceRange();
    }
);
function formatCurrency(value) {
  return value.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
</script>
<style scoped>
.grid-item {
  background-color: #fff;
  display: grid;
  grid-template-columns: 60px 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  gap: 0 .5rem;
  max-height: 120px;
  margin: 0 auto .5rem auto;
  padding: .5rem;
  border-radius: 1.2rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);}

.image {
  width: 100%;
  //height: 100%;
  object-fit: cover;
  border-radius: 2px;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 1;
}

.name {
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 2;
  grid-column-end: 4;
  text-align: left;
  font-size: 0.9rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0;
  display: inline;
}

::v-deep .input-number {
  grid-row-start: 2;
  grid-row-end: 2;
  grid-column-start: 2;
  grid-column-end: 2;
  justify-self: center;
  align-self: center;

}

::v-deep .p-inputnumber {
  display: flex;
  max-width: 100%;
  justify-self: self-start;
}

::v-deep .p-inputnumber .p-inputwrapper {
  display: flex;
  max-width: 100%;
}

::v-deep .p-inputnumber .p-inputtext {
  text-align: center; /* Centraliza o texto dentro do input */
  padding: 0.5rem; /* Adiciona padding ao input */
  border: 1px solid #ced4da; /* Borda do input */
  border-radius: 0 0 0 0; /* Bordas arredondadas */
  flex-grow: 1; /* Faz com que o input cresça para preencher o espaço */
  max-width: 4rem;
  max-height: 1.7rem;

  font-size: 1rem;
}

::v-deep .p-inputnumber .p-inputtext:focus {
  //border-color: #80bdff; /* Cor da borda ao focar no input */
  outline: none; /* Remove outline */
  //box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Adiciona sombra ao focar */
}

::v-deep .p-inputnumber .p-inputnumber-input {
  height: 100%;
  max-height: 2rem;
}


::v-deep .p-inputnumber .p-inputnumber-button {
  background-color: #DD0B1C; /* Cor de fundo dos botões */
  //border: none; /* Remove bordas */
  color: white; /* Cor do texto dos botões */
  transition: background-color 0.3s; /* Transição suave para a cor de fundo */
  //border-radius: 0.25rem;
  border: none;
  max-height: 1.7rem;
  max-width: 1rem;
}

::v-deep .p-inputnumber .p-inputnumber-button.p-inputnumber-button-up {
  border-radius: 0 .25rem .25rem 0; /* Bordas arredondadas apenas para o botão up */
  max-height: 2rem;
  max-width: 1.5rem;
}

::v-deep .input-number .p-inputnumber-button.p-inputnumber-button-down  {
  border-radius: .25rem 0   0 .25rem; /* Bordas arredondadas apenas para o botão up */
  max-height: 2rem;
  max-width: 1.5rem;
}


.input-number .p-inputnumber-button:hover {
  background-color: #0056b3; /* Cor de fundo dos botões ao passar o mouse */
}

.input-number .pi.pi-check {
  font-size: .8rem; /* Tamanho do ícone */
}

.trash {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 3;
  grid-column-end: 3;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
  align-self: start;
  justify-self: end;
  display: none;
}


.price {
  grid-row-start: 2;
  grid-row-end: 2;
  grid-column-start: 3;
  grid-column-end: 3;
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  align-self: center;
  justify-self: end;
  white-space: nowrap;
}

.price-max {
  grid-row-start: 2;
  grid-row-end: 2;
  grid-column-start: 3;
  grid-column-end: 3;
  font-size: 0.8rem;
  font-weight: 600;
  color: #333;
  justify-self: end;
  align-self: center;
  margin-bottom: 1.8rem;

  text-decoration: line-through;
  display: inline;

}


</style>