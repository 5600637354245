<script setup>

import {onMounted, ref} from "vue";
import useProductStore from "@/store/ProductsStore";

const store = useProductStore();
const filteredBonus = ref([]);

const loadingBonus = ref(true)
const bonusPromise = store.dispatch("getBonus", {
  flexibleClient: JSON.parse(localStorage.getItem("user"))?.current_client.clientCode,
  perPage: 24,
  currentPage: 1,
});

onMounted(async () => {
  await Promise.all([bonusPromise.then(() => {
    filteredBonus.value = store.state.bonuses;
    loadingBonus.value = false;
  }),])
})
</script>

<template>
  <div>
    <h1>Bonus</h1>

    <div v-if="loadingBonus">Carregando...</div>
    <div v-else>
      <div v-for="bonus in filteredBonus" :key="bonus.id">
        {{ bonus }}
      </div>

    </div>
  </div>


</template>

<style scoped>

</style>