import { createStore } from "vuex";
import axios from "@/axios/axios_campaigns";
import { ref } from "vue";

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default () => createStore({
  state: {
    campaigns: ref([]),
    campaign: ref({}),
    details: ref({}),
    
    currentCampaign: ref({}),

    accumulatedPeriods: ref([]),

    bannerImages: ref([]),
    loading: false,
  },

  mutations: {
    setCampaigns(state, data) {
      state.campaigns = ref(data);
    },
    setBanner(state, data) {
      state.bannerImages = ref(data);
    },
    setCurrentCampaign(state, data) {
      state.campaign = ref(data);
    },
    setDetails(state, data) {
      state.details = ref(data);
    },
    setAccumulatedPeriods(state, data) {
      state.accumulatedPeriods = ref(data);
    },
  },

  actions: {
    async getCampaigns(context, params) {

      context.state.loading = true;

      const { flexibleClient } = params;

      try {
        const URL = '/campaigns/flexibles/clientCampaignTerm'
        context.state.loading = true;
        const response = await axios.get(URL, {
          headers: {
            flexibleClient,
          }
        });

        context.commit('setCampaigns', response.data);

        context.state.loading = false;

      } catch (error) {
        console.log(error);
        if(error?.response?.data?.details) {
          toast.error(error?.response?.data?.details);
        }
        context.state.loading = false;
      }
    },
    async getBanner(context, params) {

      context.state.loading = true;

      const { flexibleClient } = params;

      try {
        const URL = '/banners'
        context.state.loading = true;
        const response = await axios.get(URL, {
          headers: {
            flexibleClient,
          }
        });

        context.commit('setBanner', response.data);

        context.state.loading = false;

      } catch (error) {
        console.log(error);
        if(error?.response?.data?.details) {
          toast.error(error?.response?.data?.details);
        }
        context.state.loading = false;
      }
    },
    async getDetails(context, params) {

      context.state.loading = true;

      const { flexibleClient, campaignId } = params;

      try {
        const URL = '/campaigns/flexibles/campaigns/details';
        context.state.loading = true;
        const response = await axios.get(URL, {
          headers: {
            flexibleclientid: flexibleClient,
            flexiblecampaignid: campaignId,
          }
        });

        context.commit('setDetails', response.data);

        context.state.loading = false;

      } catch (error) {
        console.log(error);
        if(error?.response?.data?.details) {
          toast.error(error?.response?.data?.details);
        }
        context.state.loading = false;
      }
    },
    async getCampaign(context, params) {

      context.state.loading = true;

      const { flexibleClient, campaignId } = params;

      try {
        const URL = '/campaigns/flexibles/campaigns/data';
        context.state.loading = true;
        const response = await axios.get(URL, {
          headers: {
            flexibleclientid: flexibleClient,
            flexiblecampaignid: campaignId,
          }
        });
        
        context.commit('setCurrentCampaign', response.data);

        context.state.loading = false;

      } catch (error) {
        console.log(error);
        if(error?.response?.data?.details) {
          toast.error(error?.response?.data?.details);
        }
        context.state.loading = false;

        throw error;
      }
    },
    async getAccumulatedPeriods(context, params) {
      context.state.loading = true;

      const { flexibleClient, campaignId } = params;

      try {
        const URL = '/campaigns/trackings/evaluation';
        context.state.loading = true;
        const response = await axios.get(URL, {
          headers: {
            flexibleClientCode: flexibleClient,
            flexibleCampaignId: campaignId,
          }
        });

        context.commit('setAccumulatedPeriods', response.data);

        context.state.loading = false;

      } catch (error) {
        console.log(error);
        if(error?.response?.data?.details) {
          toast.error(error?.response?.data?.details);
        }
        context.state.loading = false;
      }
    },
    async changeTermStatus(context, params) {

      context.state.loading = true;

      const { flexibleClient, campaignId, clientTermStatusesId } = params;

      try {
        const URL = '/campaigns/flexibles/clientCampaignTerm';
        context.state.loading = true;
        const response = await axios.put(URL, {
          clientTermStatusesId,
        }, {
          headers: {
            flexibleClientsCode: flexibleClient,
            flexibleCampaignsId: campaignId,
          },
        });
      

        context.state.loading = false;

        if (clientTermStatusesId == 1) {
          toast.success("Participação confirmada!");
        } else {
          toast.success("Rejeição confirmada!");
        }

        return response.data
      } catch (error) {
        console.log(error);
        if(error?.response?.data?.details) {
          toast.error(error?.response?.data?.details);
        }
        context.state.loading = false;
      }
    },
  },
});