import "./assets/js/nav-pills.js";

if(process.env.SEGMENT){
  // await import("./assets/scss/brasal_incorporacoes.scss");
  await import(`./assets/scss/segments/${process.env.SEGMENT}.scss`);
}else{
  await import("./assets/scss/argon-dashboard.scss");
}

export default {
  install() {},
};
