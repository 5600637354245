<template>
  <main class="main-content main-content-bg mt-0">
    <div
class="page-header min-vh-100" :style="{
      backgroundImage: 'url(' + require('@/assets/img/refrigerantes02.jpg') + ')'
    }">
      <div class="container row justify-content-center mx-auto">
        <div class="col-12 col-lg-5">
          <div v-if="!registerStore.state.restricted && !registerStore.state.finished" class="card bg-white shadow border-0 p-4">
            <div class="card-header bg-transparent pb-2">
              <h5 class="text-center">Validar Token</h5>
              <p class="text-center text-muted mb-0">Você recebeu um token de 6 digitos por e-mail, este código é válido por um dia. Digite abaixo e finalize o seu cadastro.</p>
            </div>
            <div class="card-body">
              <form>
                <div class="form-group">
                  <div class="row justify-content-center">
                    <div v-for="(item, index) in 6" :key="index" class="col-2  p-0">
                      <input
                          v-model="token[index]"
                          class="form-control text-center  w-90"
                          maxlength="1"
                          type="text"
                          @input="handleInput(index)"
                      >
                    </div>
                  </div>
                </div>
                <div class="text-center w-100">
                  <button type="button" class="btn btn-primary my-4 w-100" @click.prevent="handleValidateToken">Validar</button>
                </div>
              </form>
            </div>
          </div>
          <FeedBack v-else-if="registerStore.state.finished" icon="success" title="Conta verificada com sucesso!" subtitle="Agora é possível acessar o sistema pelo link abaixo." />
          <FeedBack v-else icon="error" :title="registerStore.state.restrictionTitle" :subtitle="registerStore.state.restrictionMessage" />
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import store from "@/store";
import {onBeforeMount, onBeforeUnmount, ref} from "vue";
import showToast from "@/utils/showToast";
import useRegisterStore from "@/store/registerStore";
import FeedBack from "@/views/conecta-brasal-cliente/auth/components/FeedBack.vue";

const registerStore = useRegisterStore();
const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");

// const returnToClient = ref(false);

let token = ref(['', '', '', '', '', '']);

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
});

onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
});

const handleInput = (index) => {
  if (index < 5 && token.value[index].length === 1) {
    // Move focus to the next input field
    document.querySelectorAll('input')[index + 1].focus();
  }
};

const handleValidateToken = () => {
  if (token.value.join('').length === 6) {
    registerStore.dispatch('validateToken', {token: token.value.join('')});

  } else {
    showToast('error', 'O token deve conter 6 digitos.')
  }
};
</script>
