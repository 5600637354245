<script setup>
import { onMounted, ref, computed } from "vue";
import useCampaignsStore from '../../../store/CampaignsStore';
import { useRoute, useRouter  } from "vue-router";
import moment from 'moment';
import Loading from "@/components/Loading.vue";

const route = useRoute();
const router = useRouter();
const store = useCampaignsStore();

const campaignLoading = ref(true);
const campaign = ref([]);

const showModal = ref(false);

const subscriptionsEnded = computed(() => {
    const now = moment();
    const finalDate = moment(campaign.value?.campaign?.final_date);
    return finalDate.isBefore(now);
})

function formatDate (dataString) {
    var partes = dataString.split("-");
    var novaData = partes[2] + "/" + partes[1] + "/" + partes[0];
    return novaData;
}

async function submit(clientTermStatusesId) {
    try {
        const campaignsPromise = store.dispatch('changeTermStatus', {
            campaignId: route.params.id,
            flexibleClient: JSON.parse(localStorage.getItem('user')).current_client.clientCode,
            clientTermStatusesId,
        });

        await Promise.all([
            campaignsPromise
                .then((response) => {
                    campaignLoading.value = false;

                    if (response && clientTermStatusesId == 1) {
                        router.push(`/campanhas/${route.params.id}`);
                    } else if (response) {
                        router.push(`/campanhas`);
                    }
                })
        ]);


    } catch (error) {
        console.error('Erro:', error);
        campaignLoading.value = false;
    }
}

onMounted(async () => {
    try {
        const campaignsPromise = store.dispatch('getDetails', {
            campaignId: route.params.id,
            flexibleClient: JSON.parse(localStorage.getItem('user')).current_client.clientCode,
        });

        await Promise.all([
            campaignsPromise.then(() => {
                campaign.value = store.state.details;
                campaignLoading.value = false;
            }),
        ]);

    } catch (error) {
        console.error('Erro:', error);
        campaignLoading.value = false;
    }
});
</script>

<template>
    <div class="container">

      <Loading v-if="campaignLoading" />

        <div v-else class="card">
            <div class="card-body">
                <h1 v-show="!campaignLoading && !campaign" class="orders-no-products">Campanha não encontrada...</h1>
                <div class="row my-2">
                    <div class="col-12 col-md-5 d-flex align-items-top justify-content-center mb-2 mb-md-0">
                        <div class="d-flex align-items-top justify-content-top">
                            <div v-if="campaign?.pictures" class="row">
                                <div class="col-md-8 m-0 w-100">
                                    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                                        <ol class="carousel-indicators">
                                            <li v-for="(picture, index) in campaign.pictures" :key="picture.id" data-target="#carouselExampleIndicators" :data-bs-slide-to="index" :class="index == 0 ? 'active' : ''"></li>
                                        </ol>
                                        <div class="carousel-inner">
                                            <div v-for="(picture, index) in campaign.pictures" :key="picture.id" :class="index == 0 ? 'carousel-item active' : 'carousel-item'">
                                                <img v-if="picture.carousel_data.based_image" class="d-block w-100 carousel-image" :src="picture.carousel_data.based_image" :alt="picture.id">
                                            </div>
                                        </div>
                                        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="campaign?.campaign" class="col-12 col-md-7">
                        <h4 class="campaign-name">{{ campaign.campaign.name }}</h4> <br>

                        <p class="card-text text-justify">
                            {{ campaign.campaign.description }}
                        </p>
                        <br>
                        <form>
                            <h5>Características da Campanha</h5>
                            <hr>
                            <div class="row">
                                <div v-if="campaign?.currentTerm?.kit" class="col-12">
                                    <div class="mb-1">
                                        <label class="form-label" for="accountTextarea">Kit de Apoio</label>
                                        <textarea id="accountTextarea" v-model="campaign.currentTerm.kit.description" readonly class="form-control textarea" rows="6"></textarea>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="mb-1">
                                        <label class="form-label" for="account-website">Período da Campanha</label>
                                        <input readonly type="text" class="form-control" :value="`${formatDate(campaign.campaign.initial_date)} - ${formatDate(campaign.campaign.final_date)}`" />
                                    </div>
                                </div>
                            </div>
                        </form>

                        <hr />
                        <div class="pt-1">
                            <div v-if="subscriptionsEnded" class="w-100">
                                <button onclick="" class="btn btn-outline-secondary ms-auto disabled">
                                    <span>Inscrições Finalizada</span>
                                </button>
                            </div>
                            <div v-else-if="campaign?.acceptance_term?.client_term_statuses_id == 1" class="d-flex flex-column  align-items-center justify-content-center">
                                <button class="btn btn-secondary w-100 btn-block" @click="showModal = true">
                                    <span>Termos de Aceite</span>
                                </button>
                                <button class="btn btn-success w-100 btn_block" @click="router.push(`/campanhas/${route.params.id}`)">
                                    <span>Ver resultados</span>
                                </button>
                            </div>
                            <div v-else-if="campaign?.acceptance_term?.client_term_statuses_id == 2" class="d-flex flex-column w-100 align-items-center justify-content-center">
                                <button class="btn btn-danger w-100" @click="submit(3)">
                                    <span>Recusar Campanha</span>
                                </button>
                                <a class="btn btn-secondary w-100" @click="showModal = true">
                                    <span>Termos de Aceite</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" :class="{ 'is-active': showModal }">
      <div class="modal-background" @click="showModal = false"></div>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Termo de Aceite da Campanha</h5>
        </div>
        <div class="modal-body">
            <div class="m-auto text-center">
                <p v-html="campaign?.currentTerm?.term"></p>
            </div>
          <br><br>
          <div v-if="campaign?.acceptance_term?.client_term_statuses_id == 2" class="form-check">
            <form id="term-form" @submit.prevent="submit(1)">
                <input id="aceite_promocao" type="checkbox" required class="form-check-input" >
                <label class="form-check-label" for="aceite_promocao">Li e aceito os termos da campanha.</label>
            </form>
          </div>
        </div>
        <div class="modal-footer">
            <button id="btn_politica" type="button" class="btn btn-primary btn-block" @click="showModal = false" >Fechar</button>
            <button v-if="campaign?.acceptance_term?.client_term_statuses_id == 2" type="submit" form="term-form" class="btn btn-success btn-block">Participar</button>
        </div>
        <input type="hidden" name="idcampanha" >
        <input type="hidden" name="tipo_campanha" >
        <input type="hidden" name="clientCode" >
      </div>
    </div>

</template>
<style>
.container {
    display: flex;
}

.content {
    flex: 1;
}

.card {
    width: 100%;
}

.card-body {
    width: 100%;
}

.carousel-inner {
    border-radius: 0.5rem;
    width: 100%;
    max-height: 500px;
}

.carousel-item {
    height: 100%;
    width: 100%;
}

.carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.textarea {
  resize: none;
}


.modal {
  display: none;
}

.modal.is-active {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 50%;
  max-height: 80%;
  overflow: auto;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}


@media screen and (max-width: 767px) {
  .campaign-name {
    margin-top: 15px;
  }
}
</style>

