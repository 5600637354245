<script setup>

import { defineProps } from "vue";

const props = defineProps({
  productName: {
    type: String,
    required: true,
  },
  productImg: {
    type: String,
    required: true,
  },
  price: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  sku: {
    type: String,
    required: true,
  },
  stock: {
    type: Number,
    required: true,
  },

  quantity: {
    type: Number,
    required: true,
  },



});





</script>


<template>
<div class="cont">
  <img class="product-image" :src="props.productImg" alt="product image" />
  <p class="product-quantity">
    {{props.quantity + "x"}}
  </p>

  <p class="product-name">
    {{props.productName }}
  </p>


  <p class="product-price">
    R$ {{(parseFloat(props.price) * props.quantity).toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}}
  </p>


</div>
</template>

<style scoped>
.cont {
  display: grid;
  grid-template-column: auto auto auto auto;
  grid-template-rows: auto auto;
  gap:  .5rem 0.3rem ;
  margin-top: .6rem;
}

.product-quantity{
  grid-column: 1;
  grid-row: 1;
  font-weight: bold;

}

.product-image {
  grid-column: 2 ;
  grid-row: 1 / 3;
  width: 95px;
  height: 95px;
  object-fit: cover;
}

.product-name {
  grid-column: 3 / 5;
  grid-row: 1;
  text-align: left;
  font-size: 0.9rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0;
  display: inline;
}

.product-price{
  grid-column: 4;
  grid-row: 2;
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  justify-self: end;
  white-space: nowrap;
  margin: 0;
}
</style>