<script setup>
import { defineProps, ref, computed, watch } from "vue";
import ProductBonus from "@/views/conecta-brasal-cliente/pedidos/novo-pedido/ProductBonus.vue";
const props = defineProps({
  productName: {
    type: String,
    required: true,
  },  
  productImg: {
    type: String,
    required: true,
  },
  price: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  sku: {
    type: String,
    required: true,
  },
  stock: {
    type: Number,
    required: true,
  },
  addToCart: {
    type: Function,
    required: true,
  },
  isOnCart: {
    type: Boolean,
    required: true,
  },
  totalOnCart: {
    type: Number,
    required: true,
  },
  pricesRanges:{
    type: Array,
    required: true
},
  minClient: {
    type: Number,
    required: false,
    default: 1,
  },
  maxClient: {
    type: Number,
    required: false,
    default: 200,
  },
  products: {
    type: Array,
    required: false,
    default: () => [],
  },
});

const total = ref(props.totalOnCart);

const usePrice = computed(() => checkPriceRange(total));


const rangesOfPrices = ref(props.pricesRanges)
const priceRanges = computed(() => listPriceRanges());

function listPriceRanges() {
  let list = [];
  if (rangesOfPrices.value.kdkg5 > 0) {
    list.push(
        `${rangesOfPrices.value.faixaVolume5} CX R$${rangesOfPrices.value.kdkg5.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} cada`
    );
  }
  if (rangesOfPrices.value.kdkg4 > 0) {
    list.push(
        `${rangesOfPrices.value.faixaVolume4} CX R$${rangesOfPrices.value.kdkg4.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} cada`
    );
  }
  if (rangesOfPrices.value.kdkg3 > 0) {
    list.push(
        `${rangesOfPrices.value.faixaVolume3} CX R$${rangesOfPrices.value.kdkg3.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} cada`
    );
  }
  if (rangesOfPrices.value.kdkg2 > 0) {
    list.push(
        `${rangesOfPrices.value.faixaVolume2} CX R$${rangesOfPrices.value.kdkg2.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} cada`
    );
  }
  return list;
}

function checkPriceRange(total) {
  let usePrice = props.price;
  if (
      total.value >= rangesOfPrices.value.faixaVolume5 &&
      rangesOfPrices.value.kdkg5 > 0
  ) {
    usePrice = rangesOfPrices.value.kdkg5;
  } else if (
      total.value >= rangesOfPrices.value.faixaVolume4 &&
      rangesOfPrices.value.kdkg4 > 0
  ) {
    usePrice = rangesOfPrices.value.kdkg4;
  } else if (
      total.value >= rangesOfPrices.value.faixaVolume3 &&
      rangesOfPrices.value.kdkg3 > 0
  ) {
    usePrice = rangesOfPrices.value.kdkg3;
  } else if (
      total.value >= rangesOfPrices.value.faixaVolume2 &&
      rangesOfPrices.value.kdkg2 > 0
  ) {
    usePrice = rangesOfPrices.value.kdkg2;
  }

  return usePrice.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

watch(
    () => props.totalOnCart,
    (newValue) => {
      total.value = newValue;
    }
);
function validateInput(event) {
  const inputValue = event.target.value;
 


  const newValue = inputValue ? parseInt(inputValue.replace(/\D/g, "")) : 0;
  event.target.value = newValue;
  total.value = newValue;

  props.addToCart(props.sku, total.value);


}
function increase() {
  total.value = total.value < props.maxClient ? total.value + 1 : props.maxClient;
  props.addToCart(props.sku, total.value);
}
function decrease() {
  total.value = total.value > 0 ? total.value - 1 : props.minClient;
  props.addToCart(props.sku, total.value);

}
</script>
<template>
  <div class="product-card">
<!--    <img-->
<!--        v-if="productImg"-->
<!--        class="product-image"-->
<!--        :src="productImg"-->
<!--        :alt="productName"-->
<!--    />-->
<!--    <img v-else class="product-image" src="@/assets/img/combo-default.jpg" />-->
    <div class="product-info">
      <div class="title-container">
<!--        <h5 class="product-title mt-1" v-for="product in props.products" :key="product.produto">-->
<!--          {{ product.quantidade + " X " + product.catalogo.DESCRICAO + " " + product.catalogo.COMPLEMENTO.replace('\\n', '').trim().replace(/\s*\n\s*/, '').replace(/\s*- R\$.*/, '')}}-->
<!--        </h5>-->
                <h5 class="product-title mt-1">
                  {{productName}}
                </h5>

      </div>
      <div class="price-ranges">
        <span v-for="(range, index) in priceRanges" :key="index">{{
            range
          }}</span>
      </div>
    </div>
    <div
        class="product-price"
    >
      <span class="price">R${{ usePrice }}</span>
      <span v-if="stock >= 200" class="stock-info text-success"
      >Disponível</span
      >
      <span v-else-if="stock" class="stock-info text-warning"
      >Estoque Baixo</span
      >
      <span v-else class="stock-info text-danger">Indisponível</span>
    </div>
    <div class="product-actions">
      <div v-if="stock" class="form-group m-0 me-2 ms-2 mb-1 w-95 product-input-div">
        <div class="input-group flex-nowrap mb-1">
          <button class="btn btn-danger mb-0" type="button" @click="decrease">
            <span>-</span>
          </button>
          <input
              v-model="total"
              class="form-control changeInputInput"
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              min="0"
              :max="maxClient"
              step="1"
              @input="validateInput"
          />
          <button class="btn btn-danger mb-0" type="button" :disabled="total >= maxClient" @click="increase">
            <span class="inputSpan">+</span>
          </button>
        </div>
      </div>
    </div>
    <div class="products-list">
      <ProductBonus v-for="product in props.products" :key="product.sku"
                    :productName="product.description "
                    :productImg="product?.image"
                    :price="product?.price"
                    :id="product.id"
                    :sku="product.sku"
                    :quantity="product.quantity"
                    />

    </div>
  </div>
</template>
<style scoped>
.disabled-link {
  pointer-events: none;
  text-decoration: none;
  cursor: not-allowed;
}
.product-card {
  height: 100%;
  position: relative;
  max-width: 20rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto auto auto auto;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  background-color: #ffffff;
  box-shadow:
      0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin: 0 auto;
  margin-top: 1rem;
  padding: 0 1.3rem;

}

.product-image {
  width: 60%;
  grid-row: 1;
  grid-column: span 5;
  margin: auto;
  margin-top: 5px;
  object-fit: contain;
  margin-bottom: 10px;
}

.product-name {
  grid-row: 1;
  grid-column: span 5;
  margin: auto;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  text-align: center;
}

.product-info {
  flex: 1;
  grid-row: 1;
  grid-column: span 5;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.product-title {
  font-size: 1rem;

}


.product-price {
  grid-row: 3;
  grid-column-start: 4;
  grid-column: span 2;

  margin-top: 0.5rem;
  margin-bottom: 1.25rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.price {
  font-size: 1.3rem;
  font-weight: 700;
  color: rgb(52, 71, 103);
}

.favorite-icon {
  cursor: pointer;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
}

.product-btn {
  width: 95%;
}



.price-ranges {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.7em;
}

.stock-info {
  font-size: 1.0em;
}


.text-warning2 {
  color: #ffbd2d;
}

.product-input-div .input-group {
  display: flex;
  width: 100%;
}

.ps-2 {
  padding-left: 10px;
}

.changeInput {
  margin-bottom: 0 !important;
  width: 5px !important;
}

.product-input {
  width: 50px !important;
  flex: 1 1 auto;
  min-width: 40px !important;
}

.input-group {
  display: flex;
  flex-wrap: nowrap;
}

.changeInputInput {
  flex: 1;
  text-align: center;
}

.product-actions {
  grid-row: 3;
  grid-column-start: 1;
  grid-column: span 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.products-list {

  grid-row: 2;
  grid-column: span 5;
}
</style>
