<script setup>
import { onMounted, ref, computed } from "vue";
import useCampaignsStore from "../../../store/CampaignsStore";
import { useRoute } from "vue-router";
import PhotoIndicator from "./indicadores/PhotoIndicator.vue";
import CoverIndicator from "./indicadores/CoverIndicator.vue";
import VolumeIndicator from "./indicadores/VolumeIndicator.vue";
import Chart from "./indicadores/Chart.vue";
import axios from "@/axios/axios_campaigns";
import showToast from "@/utils/showToast";
import Loading from "@/components/Loading.vue";

const route = useRoute();
const store = useCampaignsStore();

const campaignData = ref({});
const campaignLoading = ref(true);
const periodsLoading = ref(true);
const accumulatedPeriods = ref([]);

const campaign = computed(() => campaignData.value?.campaign);
const campaignName = computed(() => campaignData.value?.campaign?.name);
const currentPeriod = computed(() =>
  campaignData.value?.formatedPeriods?.currentPeriod?.id
    ? campaignData.value?.formatedPeriods?.currentPeriod
    : null
);
const historicPeriods = computed(() =>
  campaignData.value?.formatedPeriods?.historicPeriods
    ? campaignData.value?.formatedPeriods?.historicPeriods
    : null
);

const evaluation = computed(() => campaignData.value?.evaluation);
const indicators = computed(() => campaignData.value?.currentPeriodIndicators);

const daysLeft = computed(() => {
  var currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  currentDate.setHours(0, 0, 0, 0);

  var finalDate = new Date(currentPeriod?.value?.final_date);

  if (finalDate > currentDate) {
    var diferencaMs = finalDate - currentDate;
    var diferencaDias = Math.ceil(diferencaMs / (1000 * 60 * 60 * 24));
    return diferencaDias;
  } else if (finalDate.toDateString() === currentDate.toDateString()) {
    return "menos de um dia!";
  } else {
    return -1;
  }
});

function formatDate(dataString) {
  if (dataString) {
    var partes = dataString.split("-");
    var novaData = partes[2] + "/" + partes[1] + "/" + partes[0];
    return novaData;
  } else {
    return "Data Inválida";
  }
}

const showIndicator = (d) => {
  const date1 = new Date(d);
  const date2 = new Date();
  return date1 < date2;
};

onMounted(async () => {
  try {
    const campaignsPromise = store.dispatch("getCampaign", {
      campaignId: route.params.id,
      flexibleClient: JSON.parse(localStorage.getItem("user")).current_client.clientCode,
    });
    const accumulatedPeriodsPromise = store.dispatch("getAccumulatedPeriods", {
      campaignId: route.params.id,
      flexibleClient: JSON.parse(localStorage.getItem("user")).current_client.clientCode,
    });

    await Promise.all([
      campaignsPromise.then(() => {
        campaignData.value = store.state.campaign;
        campaignLoading.value = false;
      }),
      accumulatedPeriodsPromise.then(() => {
        accumulatedPeriods.value = store.state.accumulatedPeriods;
        periodsLoading.value = false;
      }),
    ]);

    await historicPeriods.value.forEach(async (element) => {
      await axios
        .get("/campaigns/flexibles/periods/evaluation", {
          headers: {
            flexiblePeriodId: element.flexible_period_id,
            clientCode: JSON.parse(localStorage.getItem("user")).current_client.clientCode,
            flexibleCampaignId: element.flexible_campaigns_id,
          },
        })
        .then((response) => {
          element.evaluation = response.data;
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.data?.details) {
            showToast("error", error.response.data.details);
          }
        });
    });
  } catch (error) {
    console.error("Erro:", error);
    campaignLoading.value = false;
    periodsLoading.value = false;
  }
});
function getMonthName(dateString) {
  const [year, month, day] = dateString.split("-");
  const date = new Date(year, month - 1, day);
  const options = { month: "long" };
  const monthName = date.toLocaleDateString("pt-BR", options);
  return monthName.charAt(0).toUpperCase() + monthName.slice(1);
}
</script>
<template>
  <div class="container">
    <div class="content">
      <Loading v-if="campaignLoading" />
      <div v-else class="card">
        <h1
          v-show="!campaignLoading && !campaignData"
          class="orders-no-products"
        >
          Campanha não encontrada...
        </h1>
        <div v-show="!campaignLoading && campaignData" class="card-body">
          <div
            style="
              width: 100%;
              text-align: center;
              font-weight: 500;
              font-size: 18px;
            "
          >
            <h3>{{ campaignName }}</h3>
          </div>
          <br />

          <div v-if="!periodsLoading && currentPeriod" class="mission-time">
            <h5
              v-if="daysLeft > 0"
              id="tempo_expira"
              class="card-text mission font-small-3"
            >
              Este período termina em
              <b style="color: red">{{ daysLeft }}</b> dias!
            </h5>
            <h5
              v-else-if="daysLeft == 0"
              id="tempo_expira"
              class="card-text mission font-small-3"
            >
              Este período termina em <b style="color: red">menos de um dia</b>!
            </h5>
            <h5
              v-else-if="daysLeft < 0"
              id="tempo_expira"
              class="card-text mission font-small-3"
            >
              <b style="color: red">Período finalizado!</b>
            </h5>
          </div>
          <div v-else-if="periodsLoading">
            <Loading class="limited-loading" />
          </div>
          <div v-else>
            <h5 class="mb-5">Nenhum período ativo no momento!</h5>
          </div>

          <div id="logo_prosp" class="my-3">
            <img
              v-if="campaign?.banner_data?.based_image"
              :src="campaign?.banner_data?.based_image"
              class="congratulation-medal"
              alt="Logo da campanha"
              style="border-radius: 10px"
            />
            <img
              v-else
              src="/img/placeholder-image.png"
              class="congratulation-medal"
              alt="Logo da campanha"
              style="border-radius: 10px"
            />
          </div>

          <div class="details-btn">
            <router-link
              style="max-width: 300px"
              :to="`/campanhas/detalhes/${route.params.id}`"
              class="btn btn-primary btn-block"
            >
              Consultar detalhes da campanha
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="
      !campaignLoading &&
      currentPeriod &&
      currentPeriod?.acceptedOnTime &&
      currentPeriod?.initial_date &&
      currentPeriod?.final_date
    "
    class="container"
  >
    <div class="content">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 text-center">
              <div class="row">
                <div class="col-12">
                  <h4>Período Vigente</h4>
                </div>
                <div class="col-12">
                  {{
                    `${formatDate(currentPeriod?.initial_date)} - ${formatDate(currentPeriod?.final_date)}`
                  }}
                </div>
              </div>
            </div>
            <div
              v-if="evaluation.clientPercentage >= 100"
              id="trophy-icon"
              style="text-align: right"
            >
              <img id="img_trofeu" width="50px" src="/img/trofeu.png" />
            </div>
          </div>

          <div>
            <div
              id="grafico_campanha"
              style="cursor: pointer"
              :class="`progress progress-bar-${evaluation?.clientPercentage >= 100 ? 'success' : 'danger'} mt-3`"
              onclick=""
            >
              <div
                class="progress-bar"
                role="progressbar"
                :aria-valuenow="evaluation?.clientPercentage"
                :aria-valuemin="evaluation?.clientPercentage"
                aria-valuemax="100"
                :style="`width: ${evaluation?.clientPercentage}%`"
              >
                {{
                  `${evaluation?.clientPercentage ? evaluation?.clientPercentage?.toLocaleString("pt-BR", { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : 0}%`
                }}
              </div>
            </div>
          </div>

          <div v-if="!indicators.length" class="col-12 text-danger mt-4 mb-3">
            <b>Nenhum indicador ativo no momento</b>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else-if="
      !currentPeriod?.acceptedOnTime && !campaignLoading && currentPeriod
    "
    class="container"
  >
    <div class="content">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 text-center">
              <div class="row">
                <div class="col-12">
                  <h4>Período Vigente</h4>
                </div>
                <div class="col-12">
                  {{
                    `${formatDate(currentPeriod?.initial_date)} - ${formatDate(currentPeriod?.final_date)}`
                  }}
                </div>
                <p
                  class="col-lg-8 col-md-10 col-sm-12 mx-auto mt-2 text-center"
                >
                  Devido à data de aceite do termo, você não está participando
                  do período vigente, mas fique tranquilo, sua participação está
                  garantida no próximo período!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="
      !campaignLoading && indicators?.length && currentPeriod?.acceptedOnTime
    "
    class="container"
  >
    <div class="content">
      <div class="card">
        <div class="card-header text-center">
          <h4>Indicadores</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="d-flex flex-column indicators-container">
              <div v-for="indicator in indicators" :key="indicator?.id">
                <PhotoIndicator
                  v-if="
                    indicator.flexible_indicator_id === 5 &&
                    (indicator.visible ||
                      showIndicator(indicator.initial_photo_period))
                  "
                  :indicator="indicator"
                  :campaign-id="route.params.id"
                  :period-id="currentPeriod?.id"
                  :disable-send="false"
                />
                <CoverIndicator
                  v-if="indicator.flexible_indicator_id === 2"
                  :indicator="indicator"
                />
                <VolumeIndicator
                  v-if="indicator.flexible_indicator_id === 1"
                  :indicator="indicator"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="
      !campaignLoading &&
      historicPeriods?.filter((historic) => historic?.acceptedOnTime)?.length
    "
    class="container"
  >
    <div class="card">
      <div class="card-header text-center">
        <h4>Histórico de Períodos</h4>
      </div>
      <div class="card-body">
        <div v-if="!historicPeriods">
          <p>Sem resultados históricos</p>
        </div>

        <div
          v-for="period in historicPeriods.filter(
            (historic) => historic?.acceptedOnTime
          )"
          v-else
          :key="period?.id"
          class="accordion-item"
        >
          <hr />
          <div :id="`flush-heading${period?.id}`" class="card-header">
            <button
              class="accordion-button collapsed"
              data-bs-toggle="collapse"
              :data-bs-target="`#histPeriod${period?.id}`"
              :aria-controls="`histPeriod${period?.id}`"
              aria-expanded="false"
            >
              <div class="row w-100">
                <div class="w-100 text-center">
                  <div id="tit_periodo_atual" class="col-12">
                    Vigência: {{ formatDate(period.initial_date) }} a
                    {{ formatDate(period.final_date) }}
                  </div>
                </div>
                <div
                  v-if="period?.evaluation?.evaluation?.clientPercentage >= 100"
                  class="col-2"
                  style="text-align: right"
                >
                  <img
                    :id="`img_trofeu_hist${period?.id}`"
                    width="50px"
                    src="/img/trofeu.png"
                  />
                </div>
              </div>
            </button>
            <div :id="`grafico_campanha_hist${period?.id}`">
              <div id="grafico_campanha">
                <div
                  style="cursor: pointer"
                  :class="`progress progress-bar-${period?.evaluation?.evaluation?.clientPercentage >= 100 ? 'success' : 'primary'}`"
                >
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :aria-valuenow="
                      period?.evaluation?.evaluation?.clientPercentage
                    "
                    :aria-valuemin="0"
                    aria-valuemax="100"
                    :style="{
                      width:
                        period?.evaluation?.evaluation?.clientPercentage + '%',
                      padding: '6px',
                    }"
                  >
                    {{
                      period?.evaluation?.evaluation?.clientPercentage.toLocaleString(
                        "pt-BR",
                        { minimumFractionDigits: 1, maximumFractionDigits: 1 }
                      )
                    }}%
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :id="`histPeriod${period?.id}`" class="collapse">
            <div v-if="period?.evaluation" class="accordion-body">
              <div id="bingo_itens" class="row match-height">
                <div
                  v-for="(indicator, index) in period?.evaluation?.indicators"
                  :key="indicator?.id"
                  class="col-12 col-lg-4 col-md-4 col-6"
                >
                  <PhotoIndicator
                    v-if="
                      indicator.flexible_indicator_id === 5 &&
                      (indicator.visible ||
                        showIndicator(indicator.initial_photo_period))
                    "
                    :indicator="indicator"
                    :campaign-id="campaign?.id"
                    :period-id="currentPeriod?.id"
                    :disable-send="true"
                  />
                  <CoverIndicator
                    v-if="indicator.flexible_indicator_id === 2"
                    :indicator="indicator"
                    :index="index"
                  />
                  <VolumeIndicator
                    v-if="indicator?.flexible_indicator_id == 1"
                    :indicator="indicator"
                    :index="index"
                  />
                </div>
              </div>
            </div>
            <div v-else class="w-100 text-center">
              <Loading class="limited-loading" short />
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
  </div>

  <div v-if="!campaignLoading && accumulatedPeriods?.length" class="container">
    <div class="card">
      <div class="row">
        <div
          v-for="period in accumulatedPeriods"
          :key="period?.id"
          class="col-lg-6 col-md-6 col-12"
        >
          <div class="card">
            <div class="card-header d-flex justify-content-between pb-0">
              <h4 class="card-title text-center">
                {{ period?.accumulated_period_description }}
              </h4>
              <h4
                v-if="period?.flexible_periods"
                class="card-title text-center"
              >
                {{
                  `${formatDate(period?.flexible_periods[0]?.initial_date)} a ${formatDate(period?.flexible_periods[period?.flexible_periods?.length - 1]?.final_date)}`
                }}
              </h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div
                  class="col-sm-2 col-12 d-flex flex-column flex-wrap text-center"
                >
                  <h1 class="font-large-2 fw-bolder mt-2 mb-0">
                    {{ period.total_client_periods_score }}
                  </h1>
                  <p class="card-text mission">Pontos</p>
                </div>
                <div class="col-sm-10 col-12 d-flex justify-content-center">
                  <div id="support-trackers-chart">
                    <div id="chart_radios2"></div>
                    <Chart :score="period.total_client_accumulated_score" />
                  </div>
                  <div class="resize-triggers">
                    <div class="expand-trigger">
                      <div></div>
                    </div>
                    <div class="contract-trigger"></div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-1">
                <div
                  v-for="subPeriod in period.flexible_periods"
                  :key="subPeriod?.id"
                  class="text-center"
                >
                  <p
                    v-if="subPeriod.initial_date && subPeriod.final_date"
                    class="card-text mission mb-50"
                  >
                    {{ `${getMonthName(subPeriod?.initial_date)}` }}
                  </p>
                  <span class="font-large-1 fw-bold">
                    {{ subPeriod.client_score }} pontos
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.limited-loading {
  max-height: 16vh;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<style scoped>
#logo_prosp {
  margin-right: 0px !important;
  text-align: right;
}

#logo_prosp img {
  background-color: lightgrey;
  width: 170px;
}
@media screen and (max-width: 800px) {
  #logo_prosp {
    margin-top: -8px;
    width: 100%;
    text-align: center;
  }

  #logo_prosp img {
    background-color: lightgrey;
    width: 250px;
  }

  .mission-time {
    text-align: center;
  }

  .details-btn {
    text-align: center;
  }
}

#trophy-icon {
  position: absolute;
  top: auto;
  width: 50px;
  right: 15px;
}

#grafico_campanha {
  height: 15px;
}

.indicators-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 15px;
  align-items: stretch;
}

@media screen and (max-width: 1445px) {
  .indicators-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 991px) {
  .indicators-container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
</style>
